import React from "react";
import { AuthContext } from "../../App";
import { Alert, Row, Col, Button, Typography } from "antd";
import config from "../../config";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
const { Text } = Typography;

/** Esta inicial del container */
const initialState = {
  hasConfirmed: false,
  hasUpgraded: false,
  isUpgrading: false,
  firmwareInfo: null,
  latestVersion: null,
  hasError: false,
  errorCode: null,
  errorMessage: null,
  isFetching: false,
};

/** Reducer que define todas las acciones */
const reducer = (state, action) => {
  switch (action.type) {
    case "VERIFY_FIRMWARE":
      return {
        ...state,
        isFetching: true,
      };
    case "ASSIGN_FIRMWARE":
      return {
        ...state,
        firmwareInfo: action.payload,
        isFetching: false,
      };
    case "UPDATING_FIRMWARE":
      return {
        ...state,
        hasConfirmed: true,
      };
    case "SHOW_RESULTS":
      return {
        ...state,
        hasUpgraded: true,
      };
    case "ERROR":
      return {
        hasError: true,
        errorMessage: action.payload,
        isFetching: false,
        hasUpgraded: false,
      };
    case "SAVE_LATEST":
      return {
        ...state,
        latestVersion: action.payload,
      };
    default:
      break;
  }
};

/** Constante principal */
export const UpgradeDevice = ({
  deviceId,
  deviceName,
  deviceVersion,
  deviceModel,
}) => {
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /** Funcion que se ejecuta una vez cargado el container */
  React.useEffect(() => {
    dispatch({
      type: "VERIFY_FIRMWARE",
    });
    fetch(config.api.url + "terminales/firmwares", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.data !== null) {
          let firmwares = resJson.data.reverse();
          let latestVersion = firmwares[0].version;
          dispatch({
            type: "SAVE_LATEST",
            payload: latestVersion,
          });
          /** Se crea el body del post futuro con la version mas nueva del firmware encontrado */
          var firmwareToUpgrade;
          firmwares.filter(function (f) {
            if (f.version === latestVersion) {
              let fileNameToCompare = f.nombreArchivo;
              if (fileNameToCompare.includes(deviceModel.toLowerCase())) {
                firmwareToUpgrade = {
                  terminalId: [deviceId],
                  firmware: {
                    version: f.version,
                    nombreArchivo: f.nombreArchivo,
                    revision: f.revision + "Z",
                    tipoTerminal: 8,
                  },
                };
              }
            }
          });
          dispatch({
            type: "ASSIGN_FIRMWARE",
            payload: firmwareToUpgrade,
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: resJson.mensaje,
          });
        }
      });
  }, [authState.token]);

  /** Funcion que hace el POST a GTI Terminales API */
  function handleSubmit(event) {
    /** Se previene el evento por default */
    if (event) {
      event.preventDefault();
    }
    /** Se prepara el body a postear */
    dispatch({
      type: "UPDATING_FIRMWARE",
    });
    var bodytoPost = JSON.stringify(state.firmwareInfo);
    fetch(config.api.url + "terminales/actualizar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: bodytoPost,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          dispatch({
            type: "ERROR",
            payload: null,
          });
        }
      })
      .then((resJson) => {
        dispatch({
          type: "SHOW_RESULTS",
        });
      });
  }

  /** Codigo de HTML */
  return (
    <div className="App">
      {state.isFetching ? (
        <GtiLoading loadingTitle="Cargando la información de firmwares" />
      ) : state.hasError ? (
        state.errorMessage !== null ? (
          <GtiStateMessage
            isSuccess={false}
            iconSize={40}
            iconColor={"red"}
            messageText={state.errorMessage}
            buttonText={"Aceptar"}
          />
        ) : (
          <GtiStateMessage
            isSuccess={false}
            iconSize={40}
            iconColor={"red"}
            messageTitle={"La actualización no fue completada con éxito"}
            messageText={
              <div>
                <Row paddingBottom={20}>
                  <Col
                    span={12}
                    style={{ textAlign: "right", paddingRight: 20 }}
                  >
                    <Text strong={true}>ID:</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "left" }}>
                    {deviceId}
                  </Col>
                </Row>
                <Row paddingBottom={20}>
                  <Col
                    span={12}
                    style={{ textAlign: "right", paddingRight: 20 }}
                  >
                    <Text strong={true}>Nombre:</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "left" }}>
                    {deviceName}
                  </Col>
                </Row>
              </div>
            }
            buttonText={"Aceptar"}
          />
        )
      ) : (
        <div>
          {!state.hasConfirmed ? (
            <div>
              <Row style={{ paddingBottom: 20 }}>
                <Col span={24}>
                  <Alert
                    type="warning"
                    description="Durante la actualización, el dispositivo no se podrá utilizar."
                    showIcon
                  />
                </Col>
              </Row>
              <Row style={{ textAlign: "left", paddingBottom: 20 }}>
                <Col span={24}>
                  <Text>
                    Estás por actualizar de la{" "}
                    <Text strong={true}>Versión {deviceVersion} </Text>a la{" "}
                    <Text strong={true}>{state.latestVersion} </Text>de la
                    terminal:{" "}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={3} style={{ marginLeft: 0, textAlign: "right" }}>
                  <Row>
                    <Text strong={true}>ID:</Text>
                  </Row>
                  <Row>
                    <Text strong={true}>Nombre: </Text>
                  </Row>
                </Col>
                <Col span={1}></Col>
                <Col span={20} style={{ textAlign: "left" }}>
                  <Row>
                    <Text>{deviceId}</Text>
                  </Row>
                  <Row>
                    <Text>{deviceName}</Text>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: "left" }}>
                <Text strong={true}>
                  {" "}
                  La actualización podría tardar unos minutos, ¿Deseas
                  continuar?{" "}
                </Text>
              </Row>
              <br />
              <Row style={{ display: "inline-block", float: "right" }}>
                <Button
                  style={{ margin: 5 }}
                  type="secondary"
                  key="back"
                  onClick={() => window.location.reload(false)}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ margin: 5 }}
                  key="submit"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Aceptar
                </Button>
              </Row>
            </div>
          ) : !state.hasUpgraded ? (
            <div>
              <Row style={{ textAlign: "left", paddingBottom: 20 }}>
                <Col span={24}>
                  <Alert
                    type="warning"
                    description="Posterior a la actualización se reiniciará la terminal."
                    showIcon
                  />
                </Col>
              </Row>
              <Row style={{ paddingBottom: 20 }}>
                <Col span={24}>
                  <GtiLoading
                    loadingTitle={
                      <Text>
                        Actualizando la terminal a la{" "}
                        <Text strong={true}>
                          Versión {state.firmwareInfo.firmware.version}
                        </Text>
                      </Text>
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <GtiStateMessage
              isSuccess={true}
              iconColor={"green"}
              iconSize={40}
              messageTitle={"La actualización fue realizada con éxito"}
              messageText={
                <div>
                  <Row paddingBottom={20}>
                    <Col
                      span={12}
                      style={{ textAlign: "right", paddingRight: 20 }}
                    >
                      <Text strong={true}>ID:</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      {deviceId}
                    </Col>
                  </Row>
                  <Row paddingBottom={20}>
                    <Col
                      span={12}
                      style={{ textAlign: "right", paddingRight: 20 }}
                    >
                      <Text strong={true}>Nombre:</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      {deviceName}
                    </Col>
                  </Row>
                  <Row paddingBottom={20}>
                    <Col
                      span={12}
                      style={{ textAlign: "right", paddingRight: 20 }}
                    >
                      <Text strong={true}>Versión:</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      {state.firmwareInfo.firmware.version}
                    </Col>
                  </Row>
                </div>
              }
              buttonText={"Aceptar"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UpgradeDevice;
