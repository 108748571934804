import { useState } from 'react';

//Este es el hook que utiliza Login

const useLogin = (callback) => {
    const [data, setData] = useState({
    });

    const handleFormSubmit = (event) => {
        if(event) event.preventDefault();
        callback();
    };

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    };

    const handleLoading = (value) => {
        setData({ isLoading: value });
    }

    return {
        handleLoading,
        handleInputChange,
        handleFormSubmit,
        data,
    }
};

export default useLogin;