/** Librerias necesarias para este componente */
import React from "react";
import { AuthContext } from "../../App";
import { Transfer, Row, Button, message } from "antd";
import config from "../../config";
import GtiLoading from "../GtiLoading";

/** Estado inicial del componente */
const initialState = {
  selectedKeys: [],
  dataSource: [],
  targetKeys: [],
  isFetching: false,
  isSubmitting: false,
  hasError: false,
  showResults: false,
  accessLevelResults: [],
};

/** Reducer con las acciones a realizar */
const reducer = (state, action) => {
  switch (action.type) {
    case "REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MOVE_KEYS":
      return {
        ...state,
        targetKeys: action.payload,
      };
    case "ASSIGN_DATASOURCE":
      return {
        ...state,
        isFetching: false,
        dataSource: action.payload,
      };
    case "ASSIGN_KEYS":
      return {
        ...state,
        targetKeys: action.payload,
      };
    case "UPDATING_GROUP":
      return {
        ...state,
        isSubmitting: true,
      };
    case "GROUP_UPDATED":
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
};

/** Componente inicial de transferencia */
export const GtiTransferDevices = ({
  jsonElement,
  id,
  leftTitle,
  rightTitle,
  width,
  height,
  onSuccessFunction
}) => {
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /** Funcion que se ejecuta una vez cargado el componente */
  React.useEffect(() => {
    obtainDevicesOnGroup();
  }, [authState.token]);

  function obtainDevicesOnGroup() {
    dispatch({
      type: "REQUEST",
    });
    /** Request para obtener los niveles de acceso */
    fetch(
      config.api.url + "grupos-acceso/" + jsonElement.id + "/niveles-acceso?cuentaId=" + id,
      {
        headers: {
          Authorization: `bearer ${authState.token}`,
        },
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw res;
      })
      .then((resJson) => {
        /** El componente de transferencia requiere tener
         * todos los datos en su dataSource antes
         * de cargar las llaves al target
         */
        const assignedDevices = [];
        const unassignedDevices = [];

        resJson.data.sinAsignar.map(function (accessLevel) {
          unassignedDevices.push({
            key: accessLevel.id,
            title: accessLevel.nombre,
            description: accessLevel.descripcion,
          });
        });
        resJson.data.asignados.map(function (accessLevel) {
          unassignedDevices.push({
            key: accessLevel.id,
            title: accessLevel.nombre,
            description: accessLevel.descripcion,
          });
          assignedDevices.push(accessLevel.id);
        });
        /** Se ejecuta la accion para asignar los valores
         * al dataSource
         */
        dispatch({
          type: "ASSIGN_DATASOURCE",
          payload: unassignedDevices,
        });
        /** Se ejecuta la accion para asignar los valores
         * al targetKey
         */
        dispatch({
          type: "ASSIGN_KEYS",
          payload: assignedDevices,
        });
      });
  }

  /** Funcion que se encarga de realizar el submit */
  function handleSubmit(event) {
    /** Se previene el evento por default */
    if (event) {
      event.preventDefault();
    }
    dispatch({
      type: "UPDATING_GROUP",
    });
    /** Se prepara el body a postear */
    var bodytoPost = JSON.stringify({
      nivelesAcceso: state.targetKeys,
      grupoEmpleadosId:
        jsonElement.gruposEmpleados.length > 0
          ? jsonElement.gruposEmpleados[0].id
          : 0,
    });

    fetch(config.api.url + "Grupos-Acceso/" + jsonElement.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: bodytoPost,
    }).then((res) => {
      if (res.ok) {
        /** Si el post fue exitoso se vuelven a cargar los
         * access level de este grupo
         */
        message.success("Cambios aplicados correctamente");
        dispatch({
          type: "GROUP_UPDATED",
        });
        onSuccessFunction();
      } else {
        message.error("Tus cambios no fueron efectuados");
        dispatch({
          type: "GROUP_UPDATED",
        });
      }
    });
  }

  /** Hook que se encarga de enviar los valores al dataSource o al targetKey */
  function handleChange(nextTargetKeys) {
    dispatch({
      type: "MOVE_KEYS",
      payload: nextTargetKeys,
    });
  }

  /** Funcion que cierra el modal con el boton "Cancelar" */
  function closeModal() {
    window.location.reload();
  }

  /** HTML del componente */
  return (
    <div className="App">
      {state.isFetching ? (
        <GtiLoading
          loadingTitle="Cargando información de las terminales"
          size="small"
          paddingTop={150}
          paddingBottom={150}
        />
      ) : (
          <div>
            <Row style={{ paddingBottom: 20 }}>
              <span>
                Selecciona las terminales que deseas asignar o desasignar al
                grupo
                </span>
            </Row>
            <Row style={{ marginLeft: 50, marginRight: 20 }}>
              <Transfer
                loading={state.isSubmitting}
                showSearch
                style={{ textAlign: "left" }}
                onChange={handleChange}
                dataSource={state.dataSource}
                targetKeys={state.targetKeys}
                titles={[leftTitle, rightTitle]}
                locale={{
                  searchPlaceholder: "Buscar...",
                  selectAll: "Seleccionar todo",
                  selectInvert: "Invertir selección",
                  itemUnit: "",
                  itemsUnit: "",
                  notFoundContent: "La lista esta vacía",
                }}
                listStyle={{
                  width: width,
                  height: height,
                }}
                render={(item) => item.title}
              />
            </Row>
            <br />
            <Row style={{ display: "inline-block", float: "right" }}>
              <Button
                style={{ margin: 5 }}
                type="secondary"
                key="back"
                onClick={closeModal}
              >
                Cancelar
                </Button>
              <Button
                style={{ margin: 5 }}
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={state.isSubmitting}
              >
                {state.isSubmitting ? "Guardando..." : "Guardar"}
              </Button>
            </Row>
          </div>
        )}
    </div>
  );
};

export default GtiTransferDevices;
