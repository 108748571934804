import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Alert,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Steps,
  Button,
  Slider,
  message,
} from "antd";
import { useStep } from "react-hooks-helper";
import { AuthContext } from "../../App";
import config from "../../config";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
const { Option } = Select;
const { Step } = Steps;

/** El layout de la forma  */
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

/** Estado inicial donde se almacena toda la información */
const initialState = {
  generalInformation: [],
  options: [],
  network: [],
  server: [],
  timeZone: null,
  timeZones: [],
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  hasError: false,
};

/** Reducer que ejecuta las acciones */

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_FORM_VALUES":
      let x = {
        ...state,
      };
      let obj = null;
      const nameArray = action.name.split(".");
      nameArray.forEach((prop, index) => {
        if (index !== nameArray.length - 1) {
          obj = !obj ? x[prop] : obj[prop];
        } else {
          obj[prop] = action.value;
        }
      });
      return x;
    case "OBTAIN_DEVICE_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICE_SUCCESS":
      return {
        ...state,
        generalInformation: action.information,
        options: action.options,
        network: action.network,
        server: action.server,
      };
    case "OBTAIN_DEVICE_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "OBTAIN_TIMEZONES_INFORMATION":
      return {
        ...state,
        isFetching: true,
        timeZones: action.payload,
      };
    case "ASSIGN_DEFAULT_VALUE":
      let y = {
        ...state,
      };
      let object = null;
      const providedArray = action.name.split(".");
      providedArray.forEach((prop, index) => {
        if (index !== providedArray.length - 1) {
          object = !object ? y[prop] : object[prop];
        } else {
          object[prop] = action.value;
        }
      });
      return y;
    case "SAVE_TIMEZONE":
      return {
        ...state,
        timeZone: action.value
      };
    case "DONE_FETCHING":
      return {
        ...state,
        isFetching: false,
      };
    case "SAVE_TIMEZONE":
      return {
        ...state,
        timeZone: action.value,
      };
    case "UPDATING_DEVICE":
      return {
        ...state,
        isUpdating: true,
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        successMessage: action.payload,
      };
    default:
      return state;
  }
};

export const UpdateDevice = ({ deviceId, onSuccessFunction }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { state: authState } = React.useContext(AuthContext);

  /** Constante que se encarga de administrar los pasos */
  const {
    index,
    navigation: { previous, next },
  } = useStep({ steps: 2 });

  /** Función que administra el cambio de Inputs */
  function handleFormChange(e) {
    const { name } = e.target;
    dispatch({
      type: "CHANGE_FORM_VALUES",
      name,
      value: e.target.value,
    });
  }

  function handleSliderChange(e) {
    dispatch({
      type: "CHANGE_FORM_VALUES",
      name: "options.volumen",
      value: e,
    });
  }

  function handleCheckboxChange(e) {
    const { name } = e.target;
    dispatch({
      type: "CHANGE_FORM_VALUES",
      name,
      value: e.target.checked,
    });
  }

  /** Función que valida que los datos ingresados dentro de IP tengan el formato correcto */
  function handleAddressInputChange(e) {
    const { name } = e.target;
    if (
      e.target.value.match(
        "^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." +
        "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." +
        "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." +
        "([01]?\\d\\d?|2[0-4]\\d|25[0-5])$"
      )
    ) {
      dispatch({
        type: "CHANGE_FORM_VALUES",
        name,
        value: e.target.value,
      });
    }
  }

  /** Funcion que administra cuando una nueva zona horaria ha sido seleccionada */
  function handleTimeZoneChange(e) {
    var objectName = "generalInformation.zonaHoraria";
    dispatch({
      type: "CHANGE_FORM_VALUES",
      name: objectName,
      value: e,
    });
  }

  /** Funcion que se ejecuta una vez cargado el container */
  React.useEffect(() => {
    dispatch({
      type: "OBTAIN_DEVICE_REQUEST",
    });
    var fetchedTimeZoneId;
    var fetchedTimeZone;
    /** Se obtiene la terminal */
    fetch(config.api.url + "Terminales/" + deviceId, {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          message.error("No se pudo obtener la información de tu terminal.");
        }
      })
      .then((resJson) => {
        fetchedTimeZoneId = resJson.data.zonaHoraria.id;
        dispatch({
          type: "OBTAIN_DEVICE_SUCCESS",
          information: resJson.data,
          network: resJson.data.red,
          options: resJson.data.opciones,
          server: resJson.data.servidor,
        });
        dispatch({
          type: "OBTAIN_TIMEZONES_INFORMATION",
        });
        fetch(config.api.url + "zonashorarias", {
          headers: {
            Authorization: `bearer ${authState.token}`,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw res;
            }
          })
          .then((resJson) => {
            let timeZones = resJson.data.map(function (timezone) {
              if (timezone.id === fetchedTimeZoneId) {
                dispatch({
                  type: "ASSIGN_DEFAULT_VALUE",
                  name: "generalInformation.zonaHoraria",
                  value: timezone.nombre,
                });
                dispatch({
                  type: "SAVE_TIMEZONE",
                  value: fetchedTimeZoneId,
                });
              }
              return (
                <Option value={timezone.id} key={timezone.nombre}>
                  {timezone.nombre}
                </Option>
              );
            });
            dispatch({
              type: "OBTAIN_TIMEZONES_INFORMATION",
              payload: timeZones,
            });
            dispatch({
              type: "DONE_FETCHING",
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_DEVICE_FAILURE",
        });
      });
  }, [authState.token]);

  /** Codigo en HTML de lo que se retorna */
  return (
    <div style={{ width: 600, height: "auto", paddingBottom: 30 }}>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        {state.isFetching ? (
          <div className="App">
            <Row style={{ paddingTop: 100, paddingBottom: 100 }}>
              <Col span={24}>
                <GtiLoading
                  loadingTitle={"Cargando la información de la terminal"}
                />
              </Col>
            </Row>
          </div>
        ) : state.hasError ? (
          <Row style={{ textAlign: "center" }}>
            <Col span={24}>
              <GtiStateMessage
                isSuccess={false}
                iconColor={"red"}
                iconSize={40}
                messageText={
                  "La modificación de tu terminal falló. Por favor inténtalo de nuevo."
                }
                buttonText={"Aceptar"}
              />
            </Col>
          </Row>
        ) : (
              <div>
                <Steps size={"small"} current={index} progressDot>
                  <Step title="Información general"></Step>
                  <Step title="Configuración de red"></Step>
                </Steps>
                <br />
                {index === 0 && (
                  <div>
                    <Form.Item label="Nombre:">
                      <Input
                        name="generalInformation.nombre"
                        value={state.generalInformation.nombre}
                        onChange={handleFormChange}
                        type="text"
                        required
                        style={{ width: 336 }}
                      />{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                    <Form.Item label="Zona horaria:">
                      <Select
                        onChange={handleTimeZoneChange}
                        name="generalInformation.zonaHoraria"
                        value={state.generalInformation.zonaHoraria}
                        required
                        style={{ width: 335 }}
                      >
                        {state.timeZones}
                      </Select>{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                    <Form.Item label="Volumen:">
                      <Slider
                        name="options.volumen"
                        step={10}
                        onChange={handleSliderChange}
                        style={{ width: 335 }}
                        value={state.options.volumen}
                      />
                    </Form.Item>
                  </div>
                )}
                {index === 1 && (
                  <div>
                    <Row style={{ textAlign: "center", paddingBottom: 20 }}>
                      <Col span={24}>
                        <Alert
                          description="Modificar los valores de red de la terminal podría provocar su desconexión."
                          type="warning"
                          showIcon
                        />
                      </Col>
                    </Row>
                    <Form.Item label="DHCP:">
                      <Checkbox
                        onChange={handleCheckboxChange}
                        name="network.dhcpHabilitado"
                        checked={state.network.dhcpHabilitado}
                      ></Checkbox>
                    </Form.Item>
                    <Form.Item label="IP:">
                      <Input
                        disabled={state.network.dhcpHabilitado}
                        onChange={handleAddressInputChange}
                        name="network.ip"
                        required
                        style={{ width: 142 }}
                        value={state.network.ip}
                      />{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                    <Form.Item label="Subnet:">
                      <Input
                        disabled={state.network.dhcpHabilitado}
                        onChange={handleAddressInputChange}
                        name="network.submascara"
                        required
                        style={{ width: 142 }}
                        value={state.network.submascara}
                      />{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                    <Form.Item label="Gateway:">
                      <Input
                        disabled={state.network.dhcpHabilitado}
                        onChange={handleAddressInputChange}
                        name="network.gateway"
                        required
                        style={{ width: 142 }}
                        value={state.network.gateway}
                      />{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                    <Form.Item label="Dirección DNS:">
                      <Input
                        onChange={handleFormChange}
                        name="network.dns"
                        style={{ width: 142 }}
                        value={state.network.dns}
                      />
                    </Form.Item>
                    <Form.Item label="Dirección del servidor:">
                      <Input
                        onChange={handleFormChange}
                        maxLength="100"
                        name="server.ip"
                        required
                        style={{ width: 142 }}
                        value={state.server.ip}
                      />{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Item>
                  </div>
                )}
                <Row>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    * Campos Obligatorios
                  </span>
                </Row>
                <br></br>
                <Row style={{ display: "inline-block", float: "right" }}>
                  <Button
                    type="primary"
                    style={{ margin: 5 }}
                    onClick={previous}
                  >
                    {index === 0 ? "Siguiente" : "Anterior"}
                  </Button>
                  <Button
                    loading={state.isUpdating}
                    style={{ margin: 5 }}
                    type="secondary"
                    htmlType="submit"
                  >
                    {state.isUpdating ? "Actualizando..." : "Terminar"}
                  </Button>
                </Row>
              </div>
            )}
      </Form>
    </div>
  );

  function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    /** Se prepara el body a postear */
    dispatch({
      type: "UPDATING_DEVICE",
    });
    var formattedJson = JSON.stringify({
      nombre: state.generalInformation.nombre,
      grupoTerminal: state.generalInformation.grupo,
      zonaHorariaId: state.generalInformation.zonaHoraria.toString().includes("(") ? state.timeZone : state.generalInformation.zonaHoraria,
      volumen: state.options.volumen,
      relay: true,
      formatoFecha: 0,
      timeoutMensaje: state.options.timeoutMensaje,
      logo: "",
      red: {
        dhcp: state.network.dhcpHabilitado,
        ip: state.network.ip,
        mascara: state.network.submascara,
        gateway: state.network.gateway,
        puerto: state.network.puerto,
        dns: state.network.dns,
      },
      tiposAutenticacion: {
        huellas: true,
        codigoBarras: true,
        proximidad: true,
        hid: true,
      },
      servidor: {
        ip: state.server.ip,
        puerto: state.server.puerto,
      },
    });
    fetch(config.api.url + "Terminales/" + deviceId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: formattedJson,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else
          dispatch({
            type: "OBTAIN_DEVICE_FAILURE",
          });
      })
      .then((resJson) => {
        message.success(resJson.mensaje);
        onSuccessFunction();
      });
  }
};
export default UpdateDevice;
