import {
  DownloadOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import React from "react";
import { CSVLink } from "react-csv";

export const GtiTableActions = ({ data, filename, functionOnReload }) => {
  return (
    <>
      <Tooltip placement="bottom" title="Exportar contenido a CSV">
        <CSVLink data={data} filename={filename}>
          <Button disabled={data < 1} type="link">
            <DownloadOutlined></DownloadOutlined>
          </Button>
        </CSVLink>
      </Tooltip>
      <Tooltip placement="bottom" title="Imprimir página">
        <Button type="link" onClick={() => window.print()}>
          <PrinterOutlined></PrinterOutlined>
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title="Actualizar contenido">
        <Button type="link" onClick={functionOnReload}>
          <ReloadOutlined></ReloadOutlined>
        </Button>
      </Tooltip>
    </>
  );
};

export default GtiTableActions;
