import React from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const GtiTableSearch = ({ placeholder, value, onChangeFunction, onSearchFunction, onResetFunction }) => {
    return (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Busca ${placeholder}`}
                value={value}
                onChange={onChangeFunction}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={onSearchFunction}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={onResetFunction} size="small" style={{ width: 90 }}>
                    Cancelar
                </Button>
            </Space>
        </div>
    );
};

export default GtiTableSearch;