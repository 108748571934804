/** Librerias importadas al container */
import React from "react";
import { Row, Col, message, Button } from "antd";
import { AuthContext } from "../../App";
/** Archivo de configuración */
import config from "../../config";
/** Componentes necesarios */
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
import GtiTable from "../../components/GtiTable";
import GtiSearch from "../../components/GtiSearch";
import GtiTableSearch from "../../components/GtiTableSearch";
import { SearchOutlined } from "@ant-design/icons";

/** Estado inicial */
const initialState = {
  accounts: [],
  isFetching: false,
  hasError: false,
};

/** Reducer de acciones  */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_ACCOUNTS_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_ACCOUNTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        accounts: action.payload,
      };
    default:
      break;
  }
};

/** Container de cuentas  */
export const Accounts = () => {
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [value, setValue] = React.useState("");
  const [dataSource, setDataSource] = React.useState(state.accounts);

  const columns = [
    {
      title: "ID",
      dataIndex: "codigo",
      key: "codigo",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.codigo - b.codigo,
      width: 90,
    },
    {
      title: "Cuenta",
      dataIndex: "nombre",
      key: "nombre",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una cuenta"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.nombre.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text, record) => (
        <Button type="link" onClick={() => verifyAccount(record.codigo)}>{text}</Button>
      ),

    },
  ];

  React.useEffect(() => {
    dispatch({
      type: "OBTAIN_ACCOUNTS_REQUEST",
    });
    fetch(config.api.url + "cuentas", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let accounts = resJson.data;
        setDataSource(accounts);
        dispatch({
          type: "OBTAIN_ACCOUNTS_SUCCESS",
          payload: accounts,
        });
      });
  }, [authState.token]);

  function verifyAccount(accountCode, token) {
    fetch(config.api.url + "grupos-terminales/" + accountCode, {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.error === null) {
          /** Si el grupo de terminales no existe entonces se crea uno nuevo */
          if (resJson.exitoso === false) {
            fetch(config.api.url + "grupos-terminales", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${authState.token}`,
              },
              body: JSON.stringify({
                nombre: accountCode,
                description: "Grupo de la cuenta: " + accountCode,
                grupoPadreId: 1,
              }),
            })
              .then((res) => {
                return res.json();
              })
              .then((resJson) => {
                if (resJson.exitoso) {
                  /** Si se creo correctamente se redirige a la pantalla principal */
                  window.location.replace(
                    "/consulta-terminales/" + accountCode
                  );
                } else {
                  message.error("Ha ocurrido un error");
                }
              });
          } else {
            /** En caso de que ya exista un grupo solo se redirige a la pantalla principal */
            window.location.replace("/consulta-terminales/" + accountCode);
          }
        } else {
          message.error(resJson.error);
        }
      });
  }

  /** Funcion que se encarga de la busqueda en la tabla de cuentas */
  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentAccounts = state.accounts;
    const formattedValue = currentValue.toLowerCase();
    const filteredAccounts = currentAccounts.filter((account) => {
      return (
        account.codigo.toLowerCase().includes(formattedValue) ||
        account.nombre.toLowerCase().includes(formattedValue)
      );
    });
    setDataSource(filteredAccounts);
  }

  /** Funcion que permite la busqueda desde cualquier columna */
  function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  return (
    <div className="App" style={{ paddingTop: 20 }}>
      {state.isFetching ? (
        <GtiLoading loadingTitle={"Cargando tus cuentas"} />
      ) : state.hasError ? (
        <GtiStateMessage
          isSuccess={false}
          iconColor={"red"}
          messageText={"Ha ocurrido un error al consultar las cuentas"}
        />
      ) : (
            <Row>
              <Col span={24}>
                <div style={{ textAlign: "right", paddingBottom: 20 }}>
                  <GtiSearch
                    placeholder={"Buscar..."}
                    value={value}
                    width={300}
                    onSearchFunction={(e) => generalSearch(e)}
                  />
                </div>
                <GtiTable
                  pageSize={3}
                  emptyText={"No hay cuentas que mostrar"}
                  columns={columns}
                  dataSource={dataSource}
                  paginationElement={"cuentas"}
                  rowKey={"codigo"}
                />
              </Col>
            </Row>
          )}
    </div>
  );
};

export default Accounts;
