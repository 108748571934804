/** Librerias necesarias para este componente */
import React from "react";
import ReactDOM from "react-dom";
import { Row, Modal as AntModal } from "antd";

/** Componente GtiModal */
export const GtiModal = ({
  modalTitle,
  modalInstruction,
  modalBody,
  modalFooter,
  modalWidth,
  modalHeight,
  isShowing,
  hide,
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <AntModal
            title={<h3>{modalTitle}</h3>}
            centered
            width={modalWidth}
            bodyStyle={{ height: modalHeight }}
            visible={isShowing}
            onCancel={hide}
            maskClosable={false}
            footer={null}
          >
            <div className="App">
              <Row>{modalInstruction}</Row>
              <Row>{modalBody}</Row>
            </div>
          </AntModal>
        </React.Fragment>,
        document.body
      )
    : null;

export default GtiModal;
