import { useState } from 'react';

const useModal = () => {
  
  const [isShowing, setIsShowing, ModalBody] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    ModalBody,
    isShowing,
    toggle,
  }
};

export default useModal;