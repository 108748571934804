import React, { useContext } from "react";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Typography,
  Tag,
} from "antd";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { AuthContext } from "../../App";
import config from "../../config";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
import GtiTable from "../../components/GtiTable";
import GtiTableActions from "../../components/GtiTableActions";
import GtiSearch from "../../components/GtiSearch";
import GtiTableSearch from "../../components/GtiTableSearch";
import { SearchOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

/** Constante de formato de fecha */
const dateFormat = "DD/MMM/YYYY hh:mm A";

/** Se realiza la primer consulta tomando el rango de fechas por default */
var endOfToday = moment().endOf("day").format();
var endDateToDisplay = moment().endOf("day").format(dateFormat);
var todayWithTimeZone = endOfToday + "Z";

var pastThreeDays = moment().subtract(3, "d").format();
var startDateToDisplay = moment(pastThreeDays).format(dateFormat);
var pastThreeDaysWithTimeZone = pastThreeDays + "Z";

/** Estado inicial de la forma */
const initialState = {
  companyName: null,
  devices: [],
  deviceLog: [],
  deviceId: 0,
  groupId: 0,
  startDate: pastThreeDaysWithTimeZone,
  endDate: todayWithTimeZone,
  isFetching: false,
  hasError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_DEVICES_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICES_SUCCESS":
      return {
        ...state,
        log: action.payload,
      };
    case "OBTAIN DEVICES FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "CHANGE_DEVICE_VALUE":
      return {
        ...state,
        deviceId: action.value,
      };
    case "OBTAIN_LOG_SUCCESS":
      return {
        ...state,
        deviceLog: action.payload,
        isFetching: false,
      };
    case "OBTAIN_LOG_BY_DEVICE_SUCCESS":
      return {
        ...state,
        deviceLog: action.payload,
        isFetching: false,
      };
    case "SET_GROUP_ID":
      return {
        ...state,
        groupId: action.value,
      };
    case "CHANGE_DATES_VALUES":
      return {
        ...state,
        startDate: action.value[0],
        endDate: action.value[1],
      };
    default:
      return state;
  }
};

export const DevicesLog = (props) => {

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { state: authState } = useContext(AuthContext);
  const [value, setValue] = React.useState();
  const [dataSource, setDataSource] = React.useState(state.deviceLog);

  /** Se establecen las columnas a utilizar */
  const columns = [
    {
      title: "Fecha y hora",
      dataIndex: "bitFechaEvento",
      key: "bitFechaEvento",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.bitFechaEvento).unix() - moment(b.bitFechaEvento).unix(),
      render: (record) => moment(record).format(dateFormat),
      width: 200,
      fixed: "left",
    },
    {
      title: "Id de la terminal",
      dataIndex: "bitSesionId",
      key: "bitSesionId",
      width: 150,
      fixed: "left",
    },
    {
      title: "Nombre de la terminal",
      dataIndex: "bitInstanciaId",
      key: "bitInstanciaId",
      width: 350,
      width: 250,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una terminal"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.bitInstanciaId.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Tipo",
      dataIndex: "bitTipoDescripcion",
      key: "bitTipoDescripcion",
      render: (record) => applyTagToType(record),
      filters: [
        { text: "Advertencia", value: "Advertencia" },
        { text: "Informativo", value: "Informativo" },
        { text: "Error", value: "Error" },
      ],
      onFilter: (value, record) => record.bitTipoDescripcion.indexOf(value) === 0,
      width: 150,
    },
    {
      title: "Descripción",
      dataIndex: "bitDetalle",
      key: "bitDetalle",
    },
  ];

  /** Se aplica un tag considerando el tipo de evento que es */
  function applyTagToType(record) {
    let color = "";
    switch (record) {
      case "Advertencia":
        color = "orange";
        break;
      case "Informativo":
        color = "blue";
        break;
      case "Error":
        color = "red";
        break;
      default:
        break;
    }
    return (
      <Tag color={color} key={record}>
        {record}
      </Tag>
    );
  };

  /** Funcion que permite la busqueda desde cualquier columna */
  function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  React.useEffect(() => {
    obtainDevicesLog();
  }, [authState.token]);

  function obtainDevicesLog() {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "terminales?query.limit=1000", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let options = resJson.data.map(function (device) {
          if (device.grupo === props.match.params.id) {
            dispatch({
              type: "SET_GROUP_ID",
              value: device.grupo,
            });
            getGeneralLog();
            return (
              <Option value={device.id} key={device.id}>
                {device.nombre}
              </Option>
            );
          } else {
            dispatch({
              type: "OBTAIN_LOG_SUCCESS",
              payload: [],
            });
          }
        });
        options.push(
          <Option key="default" value="Cargando">
            Todas las terminales
          </Option>
        );
        dispatch({
          type: "OBTAIN_DEVICES_SUCCESS",
          payload: options,
        });
      });
  }

  function getGeneralLog(startDate, endDate) {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    state.deviceId = 0;
    fetch(config.api.url + "bitacora?limit=1000", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: JSON.stringify({
        fechaInicio: startDate === null ? state.startDate : startDate,
        fechaFinal: endDate === null ? state.endDate : endDate,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let filteredResponse = resJson.data.filter(
          (log) => log.bitClase === 107
        );
        dispatch({
          type: "OBTAIN_LOG_SUCCESS",
          payload: filteredResponse,
        });
        setDataSource(filteredResponse);
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_LOG_FAILURE",
        });
      });
  }

  function getLogByDevice(deviceId, startDate, endDate) {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "bitacora/terminal/" + deviceId + "?limit=1000", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: JSON.stringify({
        fechaInicio: startDate === null ? state.startDate : startDate,
        fechaFinal: endDate === null ? state.endDate : endDate,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let filteredResponse = resJson.data.filter(
          (log) => log.bitClase === 107
        );
        dispatch({
          type: "OBTAIN_LOG_BY_DEVICE_SUCCESS",
          payload: filteredResponse,
        });
        setDataSource(filteredResponse);
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_LOG_FAILURE",
        });
      });
  }

  function handleDeviceChange(e) {
    if (e !== "Cargando") {
      dispatch({
        type: "CHANGE_DEVICE_VALUE",
        value: e,
      });
      getLogByDevice(e, null, null);
    } else {
      dispatch({
        type: "CHANGE_DEVICE_VALUE",
        value: 0,
      });
      getGeneralLog();
    }
  }

  function handleDateChange(value, dateString) {
    var formattedStartDate = moment(value[0]).format() + "Z";
    var formattedEndDate = moment(value[1]).format() + "Z";
    let formattedDates = [formattedStartDate, formattedEndDate];
    dispatch({
      type: "CHANGE_DATES_VALUES",
      value: formattedDates,
    });
    if (state.deviceId !== 0) {
      getLogByDevice(state.deviceId, formattedStartDate, formattedEndDate);
    } else {
      if (state.groupId === props.match.params.id)
        getGeneralLog(formattedStartDate, formattedEndDate);
    }
  }

  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentDeviceLog = state.deviceLog;
    const formattedValue = currentValue.toLowerCase();
    const filteredLog = currentDeviceLog.filter((log) => {
      return (
        log.bitTipoDescripcion.toLowerCase().includes(formattedValue) ||
        log.bitDetalle.toLowerCase().includes(formattedValue) ||
        log.bitInstanciaId.toLowerCase().includes(formattedValue)
      );
    });
    setDataSource(filteredLog);
  }

  return (
    <div>
       <Row style={{ textAlign: "left" }}>
        <Col>
          <Col>
            <Text>Terminal:</Text>
          </Col>
          <Col style={{ textAlign: "left", paddingRight: 20 }}>
            <Select
              style={{ width: 400 }}
              defaultValue={"Cargando"}
              onChange={handleDeviceChange}
            >
              {state.log}
            </Select>
          </Col>
        </Col>
        <Col>
          <Col>
            <Text>Fecha:</Text>
          </Col>
          <Col style={{ paddingRight: 20 }}>
            <RangePicker
              showTime
              locale={locale}
              style={{ width: 400 }}
              defaultValue={[
                moment(startDateToDisplay, dateFormat),
                moment(endDateToDisplay, dateFormat),
              ]}
              format={dateFormat}
              placeholder={["Fecha inicial", "Fecha final"]}
              onChange={handleDateChange}
            />
          </Col>
        </Col>
        <Col>
          <Col style={{ paddingTop: 23}}>
            <GtiSearch
              placeholder={"Buscar..."}
              value={value}
              width={300}
              onSearchFunction={(e) => generalSearch(e)}
            />
          </Col>
        </Col>
      </Row>
      <Row style={{ textAlign: "left", paddingTop: 20, paddingBottom: 20 }}>
        <GtiTableActions
          data={state.deviceLog}
          filename={"bitacora_" + Date.now() + ".csv"}
          functionOnReload={() => obtainDevicesLog()}
        />
      </Row>
      <Row>
        <Col span={24}>
          {state.isFetching ? (
            <GtiLoading
              paddingTop={100}
              loadingTitle={"Cargando la bitácora de eventos"}
            />
          ) : state.hasError ? (
            <GtiStateMessage
              isSuccess={false}
              iconColor={"red"}
              messageText={"Ha ocurrido un error al consultar la bitácora"}
            />
          ) : (
                <Col span={24}>
                  <GtiTable
                    rowKey={"bitId"}
                    pageSize={20}
                    columns={columns}
                    dataSource={dataSource}
                    paginationElement={"eventos"}
                    emptyText={
                      "No se encontraron eventos registrados en este rango de fechas"
                    }
                  />
                </Col>
              )}
        </Col>
      </Row>
    </div>
  );
};

export default DevicesLog;
