import React, { useContext } from "react";
import GtiCard from "../../components/GtiCard";
import { Link } from "react-router-dom";
import { Typography, Row, Col } from "antd";
import { AuthContext } from "../../App";
import config from "../../config";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
import Modal from "antd/lib/modal/Modal";
import GtiTableActions from "../../components/GtiTableActions";
import GtiTransferDevices from "../../components/GtiTransferDevices";
import GtiSearch from "../../components/GtiSearch";
const { Text, Paragraph } = Typography;

var companies = [];

/** Estado inicial del container */
const initialState = {
  companyId: 0,
  isFetching: false,
  hasError: false,
  accountName: null,
  accessGroups: [],
  jsonAccessGroups: [],
  csvDataSource: [],
};

/** Acciones que se realizan */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_COMPANY_SUCCESS":
      return {
        ...state,
        accountName: action.payload,
      };
    case "REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "SUCCESS":
      return {
        ...state,
        isFetching: false,
        accessGroups: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        isFetching: false,
        accessGroup: action.payload,
      };
    case "SET_CSV_DATA":
      return {
        ...state,
        csvDataSource: action.payload,
      };
    case "SAVE_JSON_ELEMENT":
      return {
        ...state,
        jsonAccessGroups: action.payload,
      };
    case "MODAL_CONTROL":
      return {
        ...state,
        visible: action.value,
      };
    case "LOAD_CONTENT":
      return {
        ...state,
        modalContent: action.payload,
      };
    case "DESTROY_MODAL":
      return {
        ...state,
        modalTitle: action.value,
        modalContent: action.payload,
      };
    default:
      break;
  }
};

/** Constante principal */
export const DevicesGroups = (props) => {
  /** Hooks a utilizar */
  const { state: authState } = useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [dataSource, setDataSource] = React.useState(state.accessGroups);
  const [value, setValue] = React.useState(value);

  /** Funcion que se ejecuta al cargar el container */
  React.useEffect(() => {
    obtainDeviceGroups();
  }, [authState.token]);

  /** Funcion que obtiene los grupos de dispositivos */
  function obtainDeviceGroups() {
    companies = [];
    dispatch({
      type: "REQUEST",
    });
    fetch(config.api.url + "cuentas/" + props.match.params.id, {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    }).then((res) => {
      if (res.ok)
        return res.json();
      else
        throw res;
    }).then((resJson) => {
      if (resJson.data.empresas.length >= 1) {
        var companyList = resJson.data.empresas;
        for (var codigo in companyList) {
          companies.push(parseInt(companyList[codigo].codigo));
        }
      }
      else {
        companies.push(0);
      }
      var bodyToPost = JSON.stringify({ empresas: companies });
      fetch(config.api.url + "Grupos-Acceso/busqueda/" + props.match.params.id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${authState.token}`,
        },
        body: bodyToPost
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((resJson) => {
          if (resJson.data.length >= 1) {
            let accessGroups = resJson.data.map(function (accessGroup) {
              return (
                <GtiCard
                  cardTitle={
                    <div style={{ textAlign: "left" }}>
                      <p style={{ marginBottom: 1 }}>
                        <Text style={{ fontSize: 14 }} type="secondary">
                          Id: {accessGroup.descripcion}
                        </Text>
                      </p>
                      <p style={{ marginBottom: 1 }}>{accessGroup.nombre}</p>
                      <p style={{ marginBottom: 1 }}>
                        <Paragraph style={{ fontSize: 14 }} type="secondary">
                          Terminales: {accessGroup.totalTerminales}
                        </Paragraph>
                      </p>
                    </div>
                  }
                  cardWidth={368}
                  cardHeight={"auto"}
                  cardMargin={20}
                  jsonElement={accessGroup}
                  cardBody={
                    <Link
                      style={{ float: "left" }}
                      onClick={() => openTransferModal(accessGroup)}
                    >
                      Asignar terminales
                  </Link>
                  }
                />
              );
            });
            let csvDataSource = resJson.data.map(function (accessGroup) {
              return {
                Código: accessGroup.id,
                Nombre: accessGroup.nombre,
                Total_de_Terminales: accessGroup.totalTerminales,
              };
            });
            dispatch({
              type: "SET_CSV_DATA",
              payload: csvDataSource,
            });
            dispatch({
              type: "SAVE_JSON_ELEMENT",
              payload: resJson.data,
            });
            dispatch({
              type: "SUCCESS",
              payload: accessGroups,
            });
            setDataSource(accessGroups);
          } else {
            dispatch({
              type: "SUCCESS",
              payload: "No hay dispositivos que mostrar",
            });
          }
        });
    });
  }

  /** Función que busca una tarjeta especifica con el buscador */
  function searchElement(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentAccessGroups = state.jsonAccessGroups;
    const formattedValue = currentValue.toLowerCase();
    var resultToShow;
    const filteredGroups = currentAccessGroups.filter(
      (accessGroup) =>
        accessGroup.id.toString().includes(formattedValue) ||
        accessGroup.nombre.toLowerCase().includes(formattedValue)
    );
    if (filteredGroups.length >= 1) {
      resultToShow = filteredGroups.map(function (accessGroup) {
        return (
          <GtiCard
            cardTitle={
              <div style={{ textAlign: "left" }}>
                <p style={{ marginBottom: 1 }}>
                  <Text style={{ fontSize: 14 }} type="secondary">
                    Id: {accessGroup.descripcion}
                  </Text>
                </p>
                <p style={{ marginBottom: 1 }}>{accessGroup.nombre}</p>
                <p style={{ marginBottom: 1 }}>
                  <Paragraph style={{ fontSize: 14 }} type="secondary">
                    Terminales: {accessGroup.totalTerminales}
                  </Paragraph>
                </p>
              </div>
            }
            cardWidth={368}
            cardHeight={"auto"}
            cardMargin={20}
            jsonElement={accessGroup}
            cardBody={
              <Link
                style={{ float: "left" }}
                onClick={() => openTransferModal(accessGroup)}
              >
                Asignar terminales
              </Link>
            }
          />
        );
      });
    }
    setDataSource(resultToShow);
  }

  function openTransferModal(accessGroup) {
    if (accessGroup != null) {
      dispatch({
        type: "LOAD_CONTENT",
        payload: (
          <div>
            <p style={{ textAlign: "left", marginBottom: 1 }}>
              <Text style={{ paddingRight: 35 }} strong>
                Id:{" "}
              </Text>
              <Text>{accessGroup.descripcion}</Text>
            </p>
            <p style={{ textAlign: "left", marginBottom: 1 }}>
              <Text style={{ paddingRight: 5 }} strong>
                Grupo:{" "}
              </Text>
              <Text>{accessGroup.nombre}</Text>
            </p>
            <GtiTransferDevices
              jsonElement={accessGroup}
              id={props.match.params.id}
              leftTitle={"Terminales Disponibles"}
              rightTitle={"Terminales Asignadas"}
              width={293}
              height={351}
              onSuccessFunction={closeModal}
            />
          </div>
        ),
      });
      dispatch({
        type: "MODAL_CONTROL",
        value: true,
      });
    }
  }

  /** Función que dispara el cierre de un Modal */
  function closeModal() {
    dispatch({
      type: "MODAL_CONTROL",
      value: false,
    });
    obtainDeviceGroups();
  }

  /** HTML */
  return (
    <div>
      <p>
        <Row>
          <GtiTableActions
            data={state.csvDataSource}
            filename={"grupos_" + Date.now() + ".csv"}
            functionOnReload={() => obtainDeviceGroups()}
          />
          <GtiSearch
            placeholder={"Buscar..."}
            value={value}
            width={300}
            onSearchFunction={(e) => searchElement(e)}
          />
        </Row>
      </p>
      <Row>
        <Col span={24}>
          {state.isFetching ? (
            <GtiLoading
              paddingTop={100}
              loadingTitle="Cargando los grupos de acceso"
            />
          ) : state.hasError ? (
            <GtiStateMessage
              isSuccess={false}
              iconColor={"red"}
              messageText={"Ha ocurrido un error"}
              buttonText={"Aceptar"}
            />
          ) : (
                <div>{dataSource}</div>
              )}
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        title={"Asignar terminales"}
        bodyStyle={{ height: "auto" }}
        centered
        width={787}
        visible={state.visible}
        maskClosable={false}
        onCancel={closeModal}
        footer={null}
      >
        {state.modalContent}
      </Modal>
    </div>
  );
};

export default DevicesGroups;
