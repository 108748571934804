/** Librerias importadas por el componente */
import React from "react";
import GtiSpinner from "../GtiSpinner";
import { Row, Col } from "antd";

/** Componente GtiLoading */
export const GtiLoading = ({ loadingTitle, paddingTop, paddingBottom }) => {
  return (
    <Row style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }}>
      <Col span={24}>
        <GtiSpinner title={loadingTitle} size="large" />
      </Col>
    </Row>
  );
};

export default GtiLoading;
