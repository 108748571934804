/** Librerias utilizadas por este container */
import React, { useContext } from "react";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Typography,
  Tag,
} from "antd";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
/** Contexto de autorización */
import { AuthContext } from "../../App";
/** Archivo de configuración */
import config from "../../config";
/** Componentes a utilizar */
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
import GtiTable from "../../components/GtiTable";
/** Containers adicionales */
import GtiTableActions from "../../components/GtiTableActions";
import GtiSearch from "../../components/GtiSearch";
import GtiTableSearch from "../../components/GtiTableSearch";
import { SearchOutlined } from "@ant-design/icons";
/** Constantes */
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

/** Constante de formato de fecha */
const dateFormat = "DD/MMM/YYYY hh:mm A";

/** Se realiza la primer consulta tomando el rango de fechas por default */
var endOfToday = moment().endOf("day").format();
var endDateToDisplay = moment().endOf("day").format(dateFormat);
var todayWithTimeZone = endOfToday + "Z";

var pastThreeDays = moment().subtract(3, "d").format();
var startDateToDisplay = moment(pastThreeDays).format(dateFormat);
var pastThreeDaysWithTimeZone = pastThreeDays + "Z";

/** Estado inicial de la forma */
const initialState = {
  companyName: null,
  devices: [],
  attendanceLog: [],
  deviceId: 0,
  groupId: 0,
  startDate: pastThreeDaysWithTimeZone,
  endDate: todayWithTimeZone,
  isFetching: false,
  hasError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_COMPANY_SUCCESS":
      return {
        ...state,
        companyName: action.payload,
      };
    case "OBTAIN_DEVICES_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICES_SUCCESS":
      return {
        ...state,
        log: action.payload,
      };
    case "OBTAIN DEVICES FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "CHANGE_DEVICE_VALUE":
      return {
        ...state,
        deviceId: action.value,
      };
    case "OBTAIN_LOG_SUCCESS":
      return {
        ...state,
        attendanceLog: action.payload,
        isFetching: false,
      };
    case "OBTAIN_LOG_BY_DEVICE_SUCCESS":
      return {
        ...state,
        attendanceLog: action.payload,
        isFetching: false,
      };
    case "SET_GROUP_ID":
      return {
        ...state,
        groupId: action.value,
      };
    case "CHANGE_DATES_VALUES":
      return {
        ...state,
        startDate: action.value[0],
        endDate: action.value[1],
      };
    default:
      return state;
  }
};

export const AttendanceLog = (props) => {
  /** Hooks a utilizar */
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { state: authState } = useContext(AuthContext);
  const [value, setValue] = React.useState();
  const [dataSource, setDataSource] = React.useState(state.attendanceLog);
  /** Se establecen las columnas a utilizar */
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Fecha",
      dataIndex: "fechaLocal",
      key: "fechaLocal",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.fechaLocal).unix() - moment(b.fechaLocal).unix(),
      render: (record) => moment(record).format(dateFormat),
      width: 160,
    },
    {
      title: "Id de la terminal",
      dataIndex: "terminalId",
      key: "terminalId",
      width: 150,
    },
    {
      title: "Nombre de la terminal",
      dataIndex: "terminalNombre",
      key: "terminalNombre",
      width: 250,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una terminal"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.terminalNombre.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Empresa",
      dataIndex: "empresaCodigo",
      key: "empresaCodigo",
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.empresaCodigo - b.empresaCodigo,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una empresa"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.empresaCodigo.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "# Empleado",
      dataIndex: "numeroEmpleado",
      key: "numeroEmpleado",
      width: 120,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.empresaCodigo - b.empresaCodigo,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"un # de empleado"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.numeroEmpleado.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Nombre de empleado(a)",
      dataIndex: "empleadoNombre",
      key: "empleadoNombre",
      width: 300,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"un nombre"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.empleadoNombre.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      render: (record) => applyTagToStatus(record),
      filters: [
        { text: "Pendiente", value: "Pendiente" },
        { text: "Procesada Con Éxito", value: "Procesada Con Exito" },
        { text: "Pendiente Reprocesar", value: "Pendiente Reprocesar" },
        { text: "Fallida", value: "Fallida" },
        { text: "En Progreso", value: "En Progreso" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      width: 150,
    },
  ];

  /** Se aplica un tag considerando el tipo de evento que es */
  function applyTagToStatus(record) {
    let color = "";
    switch (record) {
      case "Pendiente":
        color = "blue";
        break;
      case "Procesada Con Exito":
        color = "green";
        break;
      case "Pendiente Reprocesar":
        color = "orange";
        break;
      case "Fallida":
        color = "red";
        break;
      case "En Progreso":
        color = "blue";
        break;
      default:
        break;
    }
    return (
      <Tag color={color} key={record}>
        {record}
      </Tag>
    );
  }

  /** Funcion que permite la busqueda desde cualquier columna */
  function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  /** Función ejecutada una vez cargado el container */
  React.useEffect(() => {
    obtainAttendanceLog();
  }, [authState.token]);

  /** Log de asistencia general */
  function obtainAttendanceLog() {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "terminales?query.limit=1000", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let options = resJson.data.map(function (device) {
          if (device.grupo === props.match.params.id) {
            dispatch({
              type: "SET_GROUP_ID",
              value: device.grupo,
            });
            getAttendanceLog();
            return (
              <Option value={device.id} key={device.id}>
                {device.nombre}
              </Option>
            );
          } else {
            dispatch({
              type: "OBTAIN_LOG_BY_DEVICE_SUCCESS",
              payload: [],
            });
          }
        });
        options.push(
          <Option key="default" value="Cargando">
            Todas las terminales
          </Option>
        );
        dispatch({
          type: "OBTAIN_DEVICES_SUCCESS",
          payload: options,
        });
      });
  }

  function getAttendanceLog(startDate, endDate) {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    state.deviceId = 0;
    fetch(config.api.url + "checadas/busqueda?req.limit=1000&req.cuenta=" + props.match.params.id, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: JSON.stringify({
        fechaInicio: startDate == null ? state.startDate : startDate,
        fechaFinal: endDate == null ? state.endDate : endDate,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatch({
          type: "OBTAIN_LOG_SUCCESS",
          payload: resJson.data,
        });
        setDataSource(resJson.data);
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_LOG_FAILURE",
        });
      });
  }

  function getLogByDevice(deviceId, startDate, endDate) {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "checadas/busqueda?req.limit=1000&req.cuenta=" + props.match.params.id, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: JSON.stringify({
        fechaInicio: startDate == null ? state.startDate : startDate,
        fechaFinal: endDate === null ? state.endDate : endDate,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let filteredResponse = resJson.data.filter(
          (log) => log.terminalId === deviceId
        );
        dispatch({
          type: "OBTAIN_LOG_BY_DEVICE_SUCCESS",
          payload: filteredResponse,
        });
        setDataSource(filteredResponse);
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_LOG_FAILURE",
        });
      });
  }

  function handleDeviceChange(e) {
    if (e !== "Cargando") {
      dispatch({
        type: "CHANGE_DEVICE_VALUE",
        value: e,
      });
      getLogByDevice(e, null, null);
    } else {
      dispatch({
        type: "CHANGE_DEVICE_VALUE",
        value: 0,
      });
      getAttendanceLog();
    }
  }

  function handleDateChange(value, dateString) {
    var formattedStartDate = moment(value[0]).format() + "Z";
    var formattedEndDate = moment(value[1]).format() + "Z";
    let formattedDates = [formattedStartDate, formattedEndDate];
    dispatch({
      type: "CHANGE_DATES_VALUES",
      value: formattedDates,
    });
    if (state.deviceId !== 0) {
      getLogByDevice(state.deviceId, formattedStartDate, formattedEndDate);
    } else {
      if (state.groupId === props.match.params.id)
        getAttendanceLog(formattedStartDate, formattedEndDate);
    }
  }

  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentAttendanceLog = state.attendanceLog;
    const formattedValue = currentValue.toLowerCase();
    const filteredLog = currentAttendanceLog.filter((log) => {
      return (
        log.id.toString().includes(formattedValue) ||
        log.terminalId.toString().includes(formattedValue) ||
        log.terminalNombre.toLowerCase().includes(formattedValue) ||
        log.empresaCodigo.toLowerCase().includes(formattedValue) ||
        log.numeroEmpleado.toString().includes(formattedValue) ||
        log.empleadoNombre.toLowerCase().includes(formattedValue)
      );
    });
    setDataSource(filteredLog);
  }

  return (
    <div>
      <Row style={{ textAlign: "left" }}>
        <Col>
          <Col>
            <Text>Terminal:</Text>
          </Col>
          <Col style={{ textAlign: "left", paddingRight: 20 }}>
            <Select
              style={{ width: 400 }}
              defaultValue={"Cargando"}
              onChange={handleDeviceChange}
            >
              {state.log}
            </Select>
          </Col>
        </Col>
        <Col>
          <Col>
            <Text>Fecha:</Text>
          </Col>
          <Col style={{ paddingRight: 20 }}>
            <RangePicker
              showTime
              locale={locale}
              style={{ width: 400 }}
              defaultValue={[
                moment(startDateToDisplay, dateFormat),
                moment(endDateToDisplay, dateFormat),
              ]}
              format={dateFormat}
              placeholder={["Fecha inicial", "Fecha final"]}
              onChange={handleDateChange}
            />
          </Col>
        </Col>
        <Col>
          <Col style={{ paddingTop: 23 }}>
            <GtiSearch
              placeholder={"Buscar..."}
              value={value}
              width={300}
              onSearchFunction={(e) => generalSearch(e)}
            />
          </Col>
        </Col>
      </Row>
      <Row style={{ textAlign: "left", paddingTop: 20, paddingBottom: 20 }}>
        <GtiTableActions
          data={state.attendanceLog}
          filename={"checadas_" + Date.now() + ".csv"}
          functionOnReload={() => getAttendanceLog()}
        />
      </Row>
      <Row>
        <Col span={24}>
          {state.isFetching ? (
            <GtiLoading
              paddingTop={100}
              loadingTitle={"Cargando la bitácora de checadas"}
            />
          ) : state.hasError ? (
            <GtiStateMessage
              isSuccess={false}
              iconColor={"red"}
              messageText={"Ha ocurrido un error"}
            />
          ) : (
                <Row>
                  <Col span={24}>
                    <GtiTable
                      rowKey={"id"}
                      pageSize={20}
                      emptyText={
                        "No se encontraron checadas en este rango de fechas"
                      }
                      paginationElement={"checadas"}
                      dataSource={dataSource}
                      columns={columns}
                    />
                  </Col>
                </Row>
              )}
        </Col>
      </Row>
    </div>
  );
};

export default AttendanceLog;
