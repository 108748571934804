import { Col, message, Row, Tag } from "antd";
import React from "react";
import { AuthContext } from "../../App";
import GtiLoading from "../../components/GtiLoading";
import GtiSearch from "../../components/GtiSearch";
import GtiStateMessage from "../../components/GtiStateMessage";
import GtiTable from "../../components/GtiTable";
import GtiTableActions from "../../components/GtiTableActions";
import config from "../../config";
import moment from "moment";
import "moment/locale/es";
import GtiTableSearch from "../../components/GtiTableSearch";
import { SearchOutlined } from "@ant-design/icons";

/** Constante de formato de fecha */
const dateFormat = "DD/MMM/YYYY hh:mm A";

const initialState = {
  devices: [],
  csvDataSource: [],
  searchedValue: null,
  isFetching: false,
  isSubmitting: false,
  hasError: false,
};

/** Acciones que se realizan en cada dispatch */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_DEVICES_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        devices: action.payload,
      };
    case "OBTAIN_DEVICES_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "SET_CSV_DATA":
      return {
        ...state,
        csvDataSource: action.payload,
      };
    default:
      return state;
  }
};

export const DevicesMonitor = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { state: authState } = React.useContext(AuthContext);
  const [dataSource, setDataSource] = React.useState(state.devices);
  const [value, setValue] = React.useState("");

  /** Columnas que se utilizan en la tabla */
  const columns = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.Id - b.Id,
      fixed: "left",
      width: 50
    },
    {
      title: "Nombre",
      dataIndex: "Nombre",
      key: "Nombre",
      width: 300,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una terminal"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.Nombre.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Firmware",
      dataIndex: "Firmware",
      key: "Firmware",
      width: 100,
    },
    {
      title: "Modelo",
      dataIndex: "Modelo",
      key: "Modelo",
      width: 100,
    },
    {
      title: "# Cliente",
      dataIndex: "ClienteId",
      key: "ClienteId",
      width: 50,
    },
    {
      title: "Nombre del cliente",
      dataIndex: "Cliente",
      key: "Cliente",
      width: 150,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una terminal"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.Cliente.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Ultimo Heartbeat",
      dataIndex: "Heartbeat",
      key: "Heartbeat",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.Heartbeat).unix() - moment(b.Heartbeat).unix(),
        render: (record) => moment(record).utcOffset(0).format("DD/MMM/YYYY HH:mm A"),
      width: 160,
    },
    {
      title: "Estatus",
      dataIndex: "Conectada",
      key: "Conectada",
      render: (record) => (
        <Tag color={record === true ? "green" : "red"} key={record}>
          {record === true ? "Conectada" : "Desconectada"}
        </Tag>
      ),
      filters: [
        { text: "Conectada", value: true },
        { text: "Desconectada", value: false },
      ],
      onFilter: (value, record) => record.Conectada === value,
      sorter: (a, b) => a.id - b.id,
      width: 200
    },
  ];

  React.useEffect(() => {
    obtainAllDevices();
  }, [authState.token]);

  function obtainAllDevices() {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.monitor_api.url + "terminales", {
      headers: {
        Authorization: `${config.monitor_api.token}`,
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else
          dispatch({
            type: "OBTAIN_DEVICES_FAILURE",
          });
      })
      .then((resJson) => {
        if (resJson.data !== null) {
          dispatch({
            type: "OBTAIN_DEVICES_SUCCESS",
            payload: resJson.data,
          });
          setDataSource(resJson.data);
          //   /** Se prepara el arreglo para la exportación a CSV */
          let csvDataSource = resJson.data.map(function (device) {
            return {
              Id: device.Id,
              Nombre: device.Nombre,
              ClienteId: device.ClienteId,
              Cliente: device.Cliente,
              Versión: device.Firmware,
              Modelo: device.Modelo,
              UltimoHeartbeat: device.Heartbeat
            };
          });
          dispatch({
            type: "SET_CSV_DATA",
            payload: csvDataSource,
          });
        } else {
          message.error(resJson.error.toString());
        }
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_DEVICES_FAILURE",
        });
      });
  };

  /** Funcion que permite la busqueda desde cualquier columna */
  function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  /** Función que le permite al usuario filtrar el elemento en tiempo real */
  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentDevices = state.devices;
    const formattedValue = currentValue.toLowerCase();
    const filteredDevices = currentDevices.filter((device) => {
      return (
        device.Id.toString().includes(formattedValue) ||
        device.Nombre.toLowerCase().includes(formattedValue) ||
        device.ClienteId.toString().toLowerCase().includes(formattedValue) ||
        device.Cliente.toLowerCase().includes(formattedValue) ||
        device.Modelo.toLowerCase().includes(formattedValue) ||
        device.Firmware.toLowerCase().includes(formattedValue)
      );
    });
    setDataSource(filteredDevices);
  };

  return (
    <div>
      <Row>
        <h2>Terminales</h2>
      </Row>
      <Row style={{ textAlign: "left", paddingTop: 20, paddingBottom: 20 }}>
        <GtiTableActions
          data={state.csvDataSource}
          filename={"terminales_" + Date.now() + ".csv"}
          functionOnReload={() => obtainAllDevices()}
        />
        <GtiSearch
          placeholder={"Buscar..."}
          value={value}
          width={300}
          onSearchFunction={(e) => generalSearch(e)}
        />
      </Row>
      <Row>
        <Col span={24}>
          {state.isFetching ? (
            <GtiLoading
              loadingTitle={"Cargando información"}
              paddingTop={100}
            />
          ) : state.hasError ? (
            <GtiStateMessage
              style={{ paddingTop: 100 }}
              iconSize={40}
              iconColor={"red"}
              messageTitle={"Ha ocurrido un error al obtener los dispositivos"}
            />
          ) : (
            <GtiTable
              rowKey={"Id"}
              dataSource={dataSource}
              columns={columns}
              emptyText={"No hay terminales que mostrar"}
              paginationElement={"terminales"}
              pageSize={15}
              showSizeChanger={false}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DevicesMonitor;