/** Librerias necesarias para este container */
import React from "react";
import { AuthContext } from "../../App";
import {
  CheckOutlined,
  ReloadOutlined,
  CloudSyncOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Col,
  Button,
  Row,
  Input,
  Tag,
  Dropdown,
  Menu,
  Modal,
  message,
} from "antd";
import useModal from "../../hooks/useModal";
import config from "../../config";
import "@ant-design/compatible/assets/index.css";
/** Componentes */
import GtiTable from "../../components/GtiTable";
import GtiModal from "../../components/GtiModal";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
/** Containers  */
import PendingDevices from "../PendingDevices";
import UpdateDevice from "../../containers/UpdateDevice";
import UpgradeDevice from "../../containers/UpgradeDevice";
import GtiTableActions from "../../components/GtiTableActions";
import GtiSearch from "../../components/GtiSearch";
import GtiTableSearch from "../../components/GtiTableSearch";
/** Constantes */
const { Search } = Input;
const { confirm } = Modal;

/** Estado inicial del componente */
const initialState = {
  devices: [],
  csvDataSource: [],
  searchedValue: null,
  groupId: null,
  isFetching: false,
  isSubmitting: false,
  hasError: false,
  visible: false,
  modalTitle: null,
  modalContent: null,
  modalWidth: null,
};

/** Acciones que se realizan en cada dispatch */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_DEVICES_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        devices: action.payload,
      };
    case "OBTAIN_DEVICES_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "SET_CSV_DATA":
      return {
        ...state,
        csvDataSource: action.payload,
      };
    default:
      return state;
    case "MODAL_CONTROL":
      return {
        ...state,
        visible: action.value,
      };
    case "CLEAR_CONTENT":
      return {
        ...state,
        modalTitle: action.value,
        modalContent: action.payload,
      };
    case "LOAD_CONTENT":
      return {
        ...state,
        modalTitle: action.value,
        modalContent: action.payload,
        modalWidth: action.width,
      };
    case "DESTROY_MODAL":
      return {
        ...state,
        modalTitle: action.value,
        modalContent: action.payload,
      };
  }
};

export const DevicesManager = (props) => {
  /** Hooks */
  const { isShowing, toggle } = useModal();
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [value, setValue] = React.useState("");
  const [dataSource, setDataSource] = React.useState(state.devices);

  /** Columnas que se utilizan en la tabla */
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      width: 250,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <GtiTableSearch
          placeholder={"una terminal"}
          value={selectedKeys[0]}
          onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearchFunction={() => searchElement(selectedKeys, confirm)}
          onResetFunction={() => handleReset(clearFilters)}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
      onFilter: (value, record) => record.nombre.toLowerCase().includes(value.toString().toLowerCase()),
      render: (text) => text,
    },
    {
      title: "Cuenta",
      dataIndex: "grupo",
      key: "grupo",
      width: 100,
    },
    {
      title: "Tipo",
      dataIndex: "modelo",
      key: "modelo",
    },
    {
      title: "Dirección IP",
      dataIndex: ["red", "ip"],
      key: "red.ip",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <Tag color={record === "Conectada" ? "green" : "red"} key={record}>
          {record}
        </Tag>
      ),
      filters: [
        { text: "Conectada", value: "Conectada" },
        { text: "Desconectada", value: "Desconectada" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Versión",
      dataIndex: ["version", "firmware"],
      width: 100,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.version.firmware - b.version.firmware,
    },
    {
      title: "Acciones",
      key: "Action",
      align: "center",
      render: (record) => renderMenuOptions(record),
      fixed: "right",
    },
  ];

  /** Funcion que permite la busqueda desde cualquier columna */
  function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  /** Función que renderiza las opciones de menú dependiendo
   * de si las terminales estan conectadas o desconectadas
   */
  function renderMenuOptions(record) {
    /** Se inicia con la variable en falso */
    var status = false;
    /** Si la terminal esta conectada su estatus es true
     * status == true (Se renderizan las opciones completas)
     * status == false (Solo se renderiza la opción borrar)
     */
    if (record.status === "Conectada") status = true;
    return (
      <div>
        <Dropdown
          overlay={
            status ? (
              <Menu>
                <Menu.Item key="1" onClick={() => openUpdateModal(record.id)}>
                  <EditOutlined />
                  Modificar
                </Menu.Item>
                <Menu.Item
                  disabled={!status}
                  key="2"
                  onClick={() => syncDevice(record.id, record.nombre)}
                >
                  <CloudSyncOutlined />
                  Sincronizar
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => rebootDevice(record.id, record.nombre)}
                >
                  <ReloadOutlined />
                  Reiniciar
                </Menu.Item>
                <Menu.Item
                  key="4"
                  onClick={() =>
                    openUpgradeModal(
                      record.id,
                      record.nombre,
                      record.version.firmware,
                      record.version.modelo
                    )
                  }
                >
                  <img
                    alt=""
                    style={{ width: 10, marginRight: 10 }}
                    src={require("../../customIcons/up-date.svg")}
                  ></img>
                  Actualizar
                </Menu.Item>
                <Menu.Item
                  key="5"
                  onClick={() => deleteDevice(record.id, record.nombre)}
                >
                  <DeleteOutlined />
                  Borrar
                </Menu.Item>
              </Menu>
            ) : (
                <Menu>
                  <Menu.Item
                    key="5"
                    onClick={() => deleteDevice(record.id, record.nombre)}
                  >
                    <DeleteOutlined />
                  Borrar
                </Menu.Item>
                </Menu>
              )
          }
        >
          <Button type="link" icon={<MoreOutlined rotate={90} />} />
        </Dropdown>
      </div>
    );
  }

  /** Si se da clic en el botón Modificar
   * esta función llama el hook que disparará
   * el modal de UpdateDevice
   */
  function openUpdateModal(deviceId) {
    if (deviceId !== null) {
      dispatch({
        type: "LOAD_CONTENT",
        value: "Modificar Terminal",
        payload: <UpdateDevice deviceId={deviceId} onSuccessFunction={closeModal} />,
        width: "auto",
      });
      dispatch({
        type: "MODAL_CONTROL",
        value: true,
      });
    }
  }

  /** Si se da clic en el botón Actualizar
   * esta función llama el hook que disparará
   * el modal de UpgradeDevice
   */
  function openUpgradeModal(
    deviceId,
    deviceName,
    deviceVersion,
    deviceModel,
    event
  ) {
    dispatch({
      type: "LOAD_CONTENT",
      value: "Actualizar terminal",
      payload: (
        <UpgradeDevice
          deviceId={deviceId}
          deviceName={deviceName}
          deviceVersion={deviceVersion}
          deviceModel={deviceModel}
        />
      ),
      width: 640,
    });
    dispatch({
      type: "MODAL_CONTROL",
      value: true,
    });
  }

  /** Función que dispara el cierre de un Modal */
  function closeModal() {
    dispatch({
      type: "MODAL_CONTROL",
      value: false,
    });
    obtainDevices();
  }

  /** Variable de estado que determina la cuenta
   * a partir del valor de URL
   */
  state.groupId = props.groupId;

  /** Hook de efecto que se ejecuta una vez cargadio el container */
  React.useEffect(() => {
    obtainDevices();
  }, [authState.token]);

  /** Función que obtiene los dispositivos autorizados en la cuenta seleccionada */
  function obtainDevices() {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "terminales?query.grupo=" + state.groupId, {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else
          dispatch({
            type: "OBTAIN_DEVICES_FAILURE",
          });
      })
      .then((resJson) => {
        if (resJson.error === null) {
          dispatch({
            type: "OBTAIN_DEVICES_SUCCESS",
            payload: resJson.data,
          });
          setDataSource(resJson.data);
          /** Se prepara el arreglo para la exportación a CSV */
          let csvDataSource = resJson.data.map(function (device) {
            return {
              Id: device.id,
              Nombre: device.nombre,
              Grupo: device.grupo,
              Tipo: device.modelo,
              Dirección_IP:
                device.red === undefined || device.red === null ? "" : device.red.ip,
              Estado: device.status,
              Versión:
                device.status === "Desconectada" ? "" : device.version.firmware,
            };
          });
          dispatch({
            type: "SET_CSV_DATA",
            payload: csvDataSource,
          });
        } else {
          message.error(resJson.error.toString());
        }
      })
      .catch((error) => {
        message.error("Ha ocurrido un error al generar el archivo CSV");
      });
  }

  /** Función que le permite al usuario filtrar el elemento en tiempo real */
  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentDevices = state.devices;
    const formattedValue = currentValue.toLowerCase();
    const filteredDevices = currentDevices.filter((device) => {
      return (
        device.id.toString().includes(formattedValue) ||
        device.nombre.toLowerCase().includes(currentValue) ||
        device.modelo.toLowerCase().includes(currentValue) ||
        device.grupo.toLowerCase().includes(currentValue) ||
        device.status.toLowerCase().includes(currentValue) ||
        device.red.ip.toLowerCase().includes(currentValue)
      );
    });
    setDataSource(filteredDevices);
  }

  /** Código en HTML */
  return (
    <div>
      <Row style={{ textAlign: "left", paddingbottom: 10 }}>
        <Button type="primary" onClick={toggle}>
          Autorizar terminales <CheckOutlined></CheckOutlined>
        </Button>
        <div className="modal_body">
          <GtiModal
            modalTitle={"Autorizar terminales"}
            modalWidth={580}
            isShowing={isShowing}
            hide={toggle}
            modalBody={
              <PendingDevices groupId={props.groupId} onSuccessFunction={toggle} onReloadFunction={obtainDevices}></PendingDevices>
            }
          />
        </div>
        <GtiTableActions
          data={state.csvDataSource}
          filename={"Terminales_Autorizadas_" + Date.now() + ".csv"}
          functionOnReload={() => obtainDevices()}
        />
        <GtiSearch
          placeholder={"Buscar..."}
          value={value}
          width={300}
          onSearchFunction={(e) => generalSearch(e)}
        />
      </Row>
      <Row>
        <Col span={24}>
          {state.isFetching ? (
            <GtiLoading
              loadingTitle={"Cargando dispositivos"}
              paddingTop={100}
            />
          ) : state.hasError ? (
            <GtiStateMessage
              style={{ paddingTop: 100 }}
              iconSize={40}
              iconColor={"red"}
              messageTitle={"Ha ocurrido un error al obtener los dispositivos"}
            />
          ) : (
                <div>
                  <Modal
                    destroyOnClose={true}
                    title={state.modalTitle}
                    centered
                    width={state.modalWidth}
                    bodyStyle={{ height: "auto" }}
                    visible={state.visible}
                    maskClosable={false}
                    onCancel={closeModal}
                    footer={null}
                  >
                    {state.modalContent}
                  </Modal>
                  <br />
                  <GtiTable
                    rowKey={"id"}
                    dataSource={dataSource}
                    columns={columns}
                    emptyText={"No se encontraron terminales autorizadas"}
                    pageSize={30}
                    paginationElement={"terminales"}
                    showSizeChanger={false}
                  />
                </div>
              )}
        </Col>
      </Row>
    </div>
  );

  /** Función que ejecuta la sincronización de una terminal */
  function syncDevice(deviceId, deviceName, event) {
    if (event) {
      event.preventDefault();
    }

    confirm({
      centered: true,
      title: "¿Deseas sincronizar la terminal?",
      content: (
        <div>
          <p style={{ marginBottom: 1 }}>
            <b>ID: </b>
            {deviceId}
          </p>
          <p>
            <b>Nombre: </b> {deviceName}
          </p>
        </div>
      ),
      okText: "Aceptar",
      cancelText: "Cancelar",
      async onOk() {
        try {
          return new Promise((resolve, reject) => {
            return fetch(
              config.api.url + "Terminales/" + deviceId + "/sincronizar",
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${authState.token}`,
                },
              }
            ).then(
              (res) => {
                if (res.ok) {
                  resolve(res);
                  message.success(
                    "La terminal ha sido sincronizada correctamente"
                  );
                  obtainDevices();
                } else {
                  reject(res);
                }
              },
              (error) => {
                message.error(error.message);
              }
            );
          });
        } catch (e) {
          return await message.error("Ocurrió un error desconocido");
        }
      },
      onCancel() { },
    });
  }

  /** Función que ejecuta la eliminación de una terminal */
  function deleteDevice(deviceId, deviceName, event) {
    if (event) {
      event.preventDefault();
    }

    confirm({
      centered: true,
      title: "¿Deseas eliminar la terminal?",
      content: (
        <div>
          <p style={{ marginBottom: 1 }}>
            <b>ID: </b>
            {deviceId}
          </p>
          <p>
            <b>Nombre: </b> {deviceName}
          </p>
        </div>
      ),
      okText: "Aceptar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          return new Promise((resolve, reject) => {
            return fetch(
              config.api.url + "terminales/" + deviceId + "/eliminar",
              {
                method: "DELETE",
                headers: {
                  Authorization: `bearer ${authState.token}`,
                },
              }
            ).then(
              (res) => {
                if (res.ok) {
                  resolve(res);
                  message.success(
                    "La terminal ha sido eliminada correctamente"
                  );
                  obtainDevices();
                } else {
                  reject(res);
                }
              },
              (error) => {
                message.error(error.message);
              }
            );
          });
        } catch (e) {
          return await message.error("Ocurrió un error desconocido");
        }
      },
      onCancel() { },
    });
  }

  /** Función que ejecuta el reinicio de una terminal */
  function rebootDevice(deviceId, deviceName, event) {
    if (event) {
      event.preventDefault();
    }
    confirm({
      centered: true,
      title: "¿Deseas reiniciar la terminal?",
      content: (
        <div>
          <p style={{ marginBottom: 1 }}>
            <b>ID: </b>
            {deviceId}
          </p>
          <p>
            <b>Nombre: </b> {deviceName}
          </p>
        </div>
      ),
      okText: "Aceptar",
      cancelText: "Cancelar",
      async onOk() {
        try {
          return new Promise((resolve, reject) => {
            return fetch(
              config.api.url + "terminales/" + deviceId + "/reiniciar",
              {
                method: "GET",
                headers: {
                  Authorization: `bearer ${authState.token}`,
                },
              }
            ).then(
              (res) => {
                if (res.ok) {
                  setTimeout(() => {
                    resolve(res);
                    message.success(
                      "La terminal ha sido reiniciada correctamente"
                    );
                    obtainDevices();
                  }, 2000);
                } else {
                  reject(res);
                }
              },
              (error) => {
                message.error(error.message);
              }
            );
          });
        } catch (e) {
          return await message.error("Ocurrió un error desconocido");
        }
      },
      onCancel() { },
    });
  }
};

export default DevicesManager;
