import React from "react";
import { Input } from "antd";

const { Search } = Input;

export const GtiSearch = ({ placeholder, value, onSearchFunction, width}) => {
    return (
        <Search
            placeholder={placeholder}
            style={{width: width}}
            value={value}
            onChange={onSearchFunction} 
        />
    );
}

export default GtiSearch;