/** Librerias importadas al componente */
import React from "react";
import { Card } from "antd";

/** Componente GtiCard */
export const GtiCard = ({
  cardTitle,
  cardBody,
  cardWidth,
  cardHeight,
  cardMargin,
}) => {
  return (
    <div style={{ display: "inline-block" }}>
      <Card
        bordered={true}
        style={{
          width: cardWidth,
          height: cardHeight,
          margin: cardMargin,
        }}
        bodyStyle={{
          backgroundColor: "#f7f9fa",
          height: 52,
          paddingTop: 15,
          margin: 1,
        }}
        title={cardTitle}
      >
        {
          cardBody
        }
      </Card>
    </div>
  );
};

export default GtiCard;
