/** Librerias necesarias para el funcionamiento de este componente */
import React from "react";
import { Table } from "antd";

/** Exporta el contenido del componente */
export const GtiTable = ({
  rowKey,
  dataSource,
  columns,
  pageSize,
  showSizeChanger,
  rowSelection,
  emptyText,
  paginationElement,
  footer
}) => {
  /**
   * rowKey = Id que se toma como llave de cada fila de datos
   * rowSelection = Valor de selección en caso de que se especifique este valor
   * dataSource = Datos que contiene la tabla
   * columns =  Columnas que van en el header
   * pageSize = Cantidad máxima de paginación
   * showSizeChanger = Valor booleano que determina si es necesario mostrar una selección de paginación
   * position = Posición del paginador
   * emptyText = Texto que se mostrará en caso de que la tabla se encuentre vacía
   */
  return (
    <Table
      rowKey={rowKey}
      rowSelection={rowSelection}
      pagination={
        dataSource.length > pageSize && {
          pageSize: pageSize,
          showSizeChanger: showSizeChanger !== undefined ? showSizeChanger : false,
          position: ["topRight"],
          showTotal: (total, range) => { return "Mostrando " + range[1] + " de " +  total + " " + paginationElement },
          size: "small",
        }
      }
      locale={{
        emptyText: emptyText,
        filterConfirm: "Aplicar",
        filterReset: "Cancelar",
        cancelSort: "Cancelar ordenamiento",
        triggerAsc: "Ordenar ascendente",
        triggerDesc: "Ordenar descendente",
      }}
      dataSource={dataSource}
      columns={columns}
      footer={footer}
    />
  );
};

export default GtiTable;
