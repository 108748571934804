/** Librerias importadas a este container */
import React from "react";
import { Row, Col, List, Button, message } from "antd";
import { AuthContext } from "../../App";
/** Archivo de configuración */
import config from "../../config";
/** Componentes a utilizar */
import GtiModal from "../../components/GtiModal";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
/** Containers */
import Accounts from "../Accounts";
/** Hooks */
import useModal from "../../hooks/useModal";

/** Estado inicial */
const initialState = {
  accounts: [],
  accountsList: [],
  isFetching: false,
  hasError: false,
};

/** Reducer */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_ACCOUNTS_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_ACCOUNTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        accounts: action.payload,
      };
    default:
      break;
  }
};

/** Container de AccountsList  */
export const AccountsList = () => {
  /** Hooks */
  const { isShowing, toggle } = useModal();
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /** Función que se ejecuta una vez que se carga el componente */
  React.useEffect(() => {
    dispatch({
      type: "OBTAIN_ACCOUNTS_REQUEST",
    });
    fetch(config.api.url + "cuentas", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        /** Obtiene las primeras 3 cuentas del usuario */
        let accounts = resJson.data.slice(0, 3).map(function (account) {
          return (
            <List.Item key={account.codigo}>
              <Button
                type="link"
                onClick={() => verifyAccount(account.codigo)}
                block
              >
                <List.Item.Meta
                  title={
                    account.nombre.length > 20
                      ? account.nombre.substring(0, 20) + "..."
                      : account.nombre
                  }
                ></List.Item.Meta>
              </Button>
            </List.Item>
          );
        });
        dispatch({
          type: "OBTAIN_ACCOUNTS_SUCCESS",
          payload: accounts,
        });
      });
  }, [authState.token]);

  function verifyAccount(accountCode, token) {
    fetch(config.api.url + "grupos-terminales/" + accountCode, {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.error === null) {
          /** Si el grupo de terminales no existe entonces se crea uno nuevo */
          if (resJson.exitoso === false) {
            fetch(config.api.url + "grupos-terminales", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${authState.token}`,
              },
              body: JSON.stringify({
                nombre: accountCode,
                description: "Grupo de la cuenta: " + accountCode,
                grupoPadreId: 1,
              }),
            })
              .then((res) => {
                return res.json();
              })
              .then((resJson) => {
                if (resJson.exitoso) {
                  /** Si se creo correctamente se redirige a la pantalla principal */
                  window.location.replace(
                    "/consulta-terminales/" + accountCode
                  );
                } else {
                  message.error("Ha ocurrido un error");
                }
              });
          } else {
            /** En caso de que ya exista un grupo solo se redirige a la pantalla principal */
            window.location.replace("/consulta-terminales/" + accountCode);
          }
        } else {
          message.error(resJson.error);
        }
      });
  }

  return state.isFetching ? (
    <GtiLoading loadingTitle={"Cargando tus cuentas"} />
  ) : state.hasError ? (
    <GtiStateMessage
      isSuccess={false}
      iconColor={"red"}
      messageText={"Ha ocurrido un error al consultar las cuentas"}
    />
  ) : (
    <div
      style={{
        height: "auto",
        width: "200px",
        padding: "24px 0",
        paddingTop: "20px",
      }}
    >
      <Row style={{ paddingBottom: 20 }}>
        <Col span={24}>
          <List itemLayout="horizontal">{state.accounts}</List>
        </Col>
      </Row>
      <div style={{ textAlign: "center" }}>
        {state.accounts.length > 1 ? (
          <Button type="link" onClick={toggle}>
            Ver todas
          </Button>
        ) : (
          <></>
        )}
      </div>
      <GtiModal
        modalTitle={"Cuenta"}
        modalInstruction={"Selecciona la cuenta a la que deseas entrar."}
        isShowing={isShowing}
        hide={toggle}
        modalBody={<Accounts />}
      />
    </div>
  );
};

export default AccountsList;
