/** Librerias importadas para este componente */
import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";

/** Componente GtiStateMessage */
export const GtiStateMessage = ({
  isSuccess,
  iconColor,
  iconSize,
  messageTitle,
  messageText,
  buttonText,
}) => {
  return (
    <Row>
      <Col span={24}>
        {/* Icono dependiendo el contexto */}
        <Row style={{ paddingBottom: 20 }}>
          <Col span={24}>
            {isSuccess ? (
              <CheckCircleOutlined
                style={{ fontSize: iconSize, color: iconColor }}
              />
            ) : (
              <CloseCircleOutlined
                style={{ fontSize: iconSize, color: iconColor }}
              />
            )}
          </Col>
        </Row>
        {/* Titulo del mensaje */}
        {messageTitle !== undefined ? (
          <Row style={{ paddingBottom: 20 }}>
            <Col span={24}>
              <h3>{messageTitle}</h3>
            </Col>
          </Row>
        ) : (
          <div></div>
        )}
        <Row style={{ paddingBottom: 30 }}>
          <Col span={24}>{messageText}</Col>
        </Row>
        <Row style={{ paddingBottom: 20 }}>
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => window.location.reload(false)}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GtiStateMessage;
