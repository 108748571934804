import { Col, message, Row, Tag, Typography } from "antd";
import React from "react";
import { AuthContext } from "../../App";
import GtiLoading from "../../components/GtiLoading";
import GtiSearch from "../../components/GtiSearch";
import GtiStateMessage from "../../components/GtiStateMessage";
import GtiTable from "../../components/GtiTable";
import GtiTableActions from "../../components/GtiTableActions";
import config from "../../config";
import moment from "moment";
import "moment/locale/es";
import GtiTableSearch from "../../components/GtiTableSearch";
import { SearchOutlined } from "@ant-design/icons";

/** Constante de formato de fecha */
const dateFormat = "DD/MMM/YYYY hh:mm A";

const initialState = {
    services: [],
    csvDataSource: [],
    searchedValue: null,
    isFetching: false,
    isSubmitting: false,
    hasError: false,
};

/** Acciones que se realizan en cada dispatch */
const reducer = (state, action) => {
    switch (action.type) {
        case "OBTAIN_SERVICES_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false,
            };
        case "OBTAIN_SERVICES_SUCCESS":
            return {
                ...state,
                isFetching: false,
                services: action.payload,
            };
        case "OBTAIN_SERVICES_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
            };
        case "SET_CSV_DATA":
            return {
                ...state,
                csvDataSource: action.payload,
            };
        default:
            return state;
    }
};

export const ServicesMonitor = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { state: authState } = React.useContext(AuthContext);
    const [dataSource, setDataSource] = React.useState(state.services);
    const [value, setValue] = React.useState("");

    /** Columnas que se utilizan en la tabla */
    const columns = [
        {
            title: "Id Cliente",
            dataIndex: "ClienteId",
            key: "ClienteId",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.ClienteId - b.ClienteId,
            fixed: "left",
            width: 100
        },
        {
            title: "Nombre Cliente",
            dataIndex: "ClienteNombre",
            key: "ClienteNombre",
            width: 200,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <GtiTableSearch
                    placeholder={"un cliente"}
                    value={selectedKeys[0]}
                    onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onSearchFunction={() => searchElement(selectedKeys, confirm)}
                    onResetFunction={() => handleReset(clearFilters)}
                />
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
            onFilter: (value, record) => record.ClienteNombre.toLowerCase().includes(value.toString().toLowerCase()),
            render: (text) => text,
        },
        {
            title: "Versión",
            dataIndex: "Version",
            key: "Version",
            width: 100,
        },
        {
            title: "Servicio",
            dataIndex: "ServicioNombre",
            key: "ServicioNombre",
            width: 150,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <GtiTableSearch
                    placeholder={"un servicio"}
                    value={selectedKeys[0]}
                    onChangeFunction={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onSearchFunction={() => searchElement(selectedKeys, confirm)}
                    onResetFunction={() => handleReset(clearFilters)}
                />
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#BB580C' : "#000000" }} />,
            onFilter: (value, record) => record.ServicioNombre.toLowerCase().includes(value.toString().toLowerCase()),
            render: (text) => text,
        },
        {
            title: "Ultimo Heartbeat",
            dataIndex: "Heartbeat",
            key: "Heartbeat",
            defaultSortOrder: "descend",
            sorter: (a, b) =>
                moment(a.Heartbeat).unix() - moment(b.Heartbeat).unix(),
                render: (record) => moment(record).utcOffset(0).format("DD/MMM/YYYY HH:mm A"),
            width: 160,
        },
        {
            title: "Minutos Desconexión",
            dataIndex: "MinutosDesconexion",
            key: "MinutosDesconexion",
            defaultSortOrder: "descend",
            render: (record) => (<Typography.Text type={ record > 60 || 30 ? "danger" : "success"}>{record}</Typography.Text>),
            width: 160,
        },
        {
            title: "Minutos Gracia",
            dataIndex: "MinutosGracia",
            key: "MinutosGracia",
            defaultSortOrder: "descend",
            render: (record) => record,
            width: 160,
        },
        {
            title: "Estatus",
            dataIndex: "Activo",
            key: "Activo",
            render: (record) => (
                <Tag color={record === true ? "green" : "red"} key={record}>
                    {record === true ? "Activo" : "Inactivo"}
                </Tag>
            ),
            filters: [
                { text: "Activo", value: true },
                { text: "Inactivo", value: false },
            ],
            onFilter: (value, record) => record.Activo === value,
            sorter: (a, b) => a.id - b.id,
            width: 200
        },
    ];

    React.useEffect(() => {
        obtainAllDevices();
    }, [authState.token]);

    function obtainAllDevices() {
        dispatch({
            type: "OBTAIN_SERVICES_REQUEST",
        });
        fetch(config.monitor_api.url + "servicios", {
            headers: {
                Authorization: `${config.monitor_api.token}`,
            },
        })
            .then((res) => {
                if (res.ok) return res.json();
                else
                    dispatch({
                        type: "OBTAIN_SERVICES_FAILURE",
                    });
            })
            .then((resJson) => {
                if (resJson.data !== null) {
                    dispatch({
                        type: "OBTAIN_SERVICES_SUCCESS",
                        payload: resJson.data,
                    });
                    setDataSource(resJson.data);
                    //   /** Se prepara el arreglo para la exportación a CSV */
                    let csvDataSource = resJson.data.map(function (service) {
                        return {
                            ClienteId: service.ClienteId,
                            ClienteNombre: service.ClienteNombre,
                            Version: service.Version,
                            ServicioNombre: service.ServicioNombre,
                            Heartbeat: service.Heartbeat,
                            MinutosGracia: service.MinutosGracia,
                            MinutosDesconexion: service.MinutosDesconexion
                        };
                    });
                    dispatch({
                        type: "SET_CSV_DATA",
                        payload: csvDataSource,
                    });
                } else {
                    message.error(resJson.error.toString());
                }
            })
            .catch((error) => {
                dispatch({
                    type: "OBTAIN_SERVICES_FAILURE",
                });
            });
    };

    /** Funcion que permite la busqueda desde cualquier columna */
    function searchElement(selectedKeys, confirm) {
        confirm();
    }

    /** Borra los filtros en cualquier columna */
    function handleReset(clearFilters) {
        clearFilters();
    }

    /** Función que le permite al usuario filtrar el elemento en tiempo real */
    function generalSearch(e) {
        const currentValue = e.target.value;
        setValue(currentValue);
        const currentDevices = state.services;
        const formattedValue = currentValue.toLowerCase();
        const filteredDevices = currentDevices.filter((device) => {
            return (
                device.ClienteId.toString().includes(formattedValue) ||
                device.ClienteNombre.toLowerCase().includes(formattedValue) ||
                device.ServicioNombre.toString().toLowerCase().includes(formattedValue) ||
                device.Heartbeat.toLowerCase().includes(formattedValue) ||
                device.MinutosGracia.toString().toLowerCase().includes(formattedValue) ||
                device.MinutosDesconexion.toString().toLowerCase().includes(formattedValue)
            );
        });
        setDataSource(filteredDevices);
    };

    return (
        <div>
            <Row>
                <h2>Servicios</h2>
            </Row>
            <Row style={{ textAlign: "left", paddingTop: 20, paddingBottom: 20 }}>
                <GtiTableActions
                    data={state.csvDataSource}
                    filename={"servicios_" + Date.now() + ".csv"}
                    functionOnReload={() => obtainAllDevices()}
                />
                <GtiSearch
                    placeholder={"Buscar..."}
                    value={value}
                    width={300}
                    onSearchFunction={(e) => generalSearch(e)}
                />
            </Row>
            <Row>
                <Col span={24}>
                    {state.isFetching ? (
                        <GtiLoading
                            loadingTitle={"Cargando información"}
                            paddingTop={100}
                        />
                    ) : state.hasError ? (
                        <GtiStateMessage
                            style={{ paddingTop: 100 }}
                            iconSize={40}
                            iconColor={"red"}
                            messageTitle={"Ha ocurrido un error al obtener los dispositivos"}
                        />
                    ) : (
                        <GtiTable
                            rowKey={"Id"}
                            dataSource={dataSource}
                            columns={columns}
                            emptyText={"No hay servicios que mostrar"}
                            paginationElement={"servicios"}
                            pageSize={15}
                            showSizeChanger={false}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ServicesMonitor;