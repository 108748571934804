/** Librerias importadas para este componente */
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/** Icono personalizado para el Spin */
const antIcon = <LoadingOutlined spin/>

/** Componente GtiSpinner */
export const GtiSpinner = ({ type, title, size}) => {
    return (
        <Spin
        indicator={antIcon}
        tip={title}
        size={size}
        type={type}
        />
    )
};

export default GtiSpinner;