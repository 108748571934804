/** Librerias a utilizar */
import React from "react";
/** Contexto de autorización */
import { AuthContext } from "../../App";

/** Este componente solo ejecuta un dispatch, por lo que no contiene aspecto visual */
export const Logout = () => {
  const { dispatch } = React.useContext(AuthContext);

  dispatch({
    type: "LOGOUT",
  });

  return <div></div>;
};

export default Logout;
