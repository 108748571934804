import React, { useContext } from "react";
import { AuthContext } from "../../App";
import config from "../../config";

const initialState = {
    accountName: null,
    hasAccountName: false
}

const reducer = (state, action) => {
    switch (action.type) {
        case "OBTAIN_ACCOUNT_SUCCESS":
            return {
                ...state,
                accountName: action.payload,
                hasAccountName: true
            };
        default:
            break;
    }
}

export const AccountHeader = ({ accountId }) => {

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { state: authState } = useContext(AuthContext);

    React.useEffect(() => {
        if (accountId !== undefined && !state.hasAccountName) {
            fetch(config.api.url + "cuentas/" + accountId, {
                headers: {
                    Authorization: `bearer ${authState.token}`,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw res;
                    }
                })
                .then((resJson) => {
                    dispatch({
                        type: "OBTAIN_ACCOUNT_SUCCESS",
                        payload: resJson.data.nombre,
                    });
                })
        }
    });

    return (
        <h2>{state.accountName}</h2>
    );
};


export default AccountHeader;