/** Librerias necesarias para este container */
import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Input,
  Button,
  Checkbox,
  Row,
  Divider,
  Col,
  Typography,
  message,
} from "antd";
import {
  LockOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
/** Contexto de autorización */
import { AuthContext } from "../../App";
/** Archivo de configuración */
import config from "../../config";
/** Componentes a utilizar */
import GtiLoading from "../../components/GtiLoading";
/** Hooks  */
import useLogin from "../../hooks/useLogin";
/** Logo del Sistema */
import sistemaTressLogo from "../../images/revolution.png";
import GtiTable from "../../components/GtiTable";
import GtiSearch from "../../components/GtiSearch";
/** Constantes */
const { Text } = Typography;

/** Estado inicial del componente */
const initialState = {
  username: null,
  token: null,
  accounts: null,
  firstStep: "block",
  secondStep: "none",
  isFetching: false,
  isSubmitting: false,
  loadingTitle: null,
  hasError: false,
};

/** Constante que maneja las acciones locales */
const reducer = (state, action) => {
  switch (action.type) {
    /** Salva la sesion de usuario y muestra las cuentas */
    case "SHOW_SECOND_STEP":
      return {
        ...state,
        firstStep: "none",
        secondStep: "block",
        isFetching: true,
      };
    /** Obtiene las cuentas del usuario */
    case "OBTAIN_ACCOUNTS":
      return {
        ...state,
        isFetching: false,
        accounts: action.payload,
      };
    /** Muestra un error */
    case "LOGIN_ERROR":
      return {
        ...state,
        isSubmitting: false,
        hasError: true,
        loginResult: action.value,
      };
    /** Establece el estado de "enviando" */
    case "IS_SUBMITTING":
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case "IS_LOGGING_IN":
      return {
        ...state,
        isFetching: true,
        loadingTitle: action.payload,
      };
    default:
      break;
  }
};

/** Constante principal del componente de Login */
export const Login = () => {
  /** Columnas de la tabla cuentas */
  const columns = [
    {
      title: "ID",
      dataIndex: "codigo",
      key: "codigo",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.codigo - b.codigo,
      width: 90,
    },
    {
      title: "Cuenta",
      dataIndex: "nombre",
      key: "nombre",
      width: 200,
      render: (text, record) => (
        <Button
          loading={state.isSubmitting}
          onClick={() => handleSubmit(record.codigo)}
          type="link"
        >
          {text}
        </Button>
      ),
    },
  ];

  /** Dispatch perteneciente al contexto de autenticación */
  const { dispatch } = React.useContext(AuthContext);
  /** Dispatch perteneciente al contexto interno del componente */
  const [state, secondDispatch] = React.useReducer(reducer, initialState);
  /** Hooks **/
  const { data, handleInputChange, handleLoading } = useLogin(login);
  const [value, setValue] = React.useState("");
  const [dataSource, setDataSource] = React.useState(state.accounts);

  /** Función asincrona de Login */
  async function login() {}

  /** Función que se ejecuta una vez que el usuario ingresa al Sistema */
  function showAccounts(event) {
    if (event) {
      event.preventDefault();
    }
    secondDispatch({
      type: "IS_SUBMITTING",
      payload: true,
    });
    var loginValues = new URLSearchParams();
    loginValues.append("username", data.username);
    loginValues.append("password", data.password);
    loginValues.append("grant_type", "password");
    var token;
    fetch(config.api.url + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-application": 2,
      },
      body: loginValues.toString(),
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.error === undefined) {
          /** Si la respuesta es exitosa entonces se guarda la sesion localmente */
          secondDispatch({
            type: "IS_SUBMITTING",
            payload: false,
          });
          secondDispatch({
            type: "SHOW_SECOND_STEP",
          });
          token = resJson.access_token;
          fetch(config.api.url + "cuentas", {
            headers: {
              Authorization: `bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw res;
              }
            })
            .then((resJson) => {
              let accounts = resJson.data;
              setDataSource(accounts);
              /** Si el usuario tiene acceso a mas de una cuenta se muestran */
              if (accounts.length > 1) {
                secondDispatch({
                  type: "OBTAIN_ACCOUNTS",
                  payload: accounts,
                });
              } else {
                /** De lo conTrario se verifica la cuenta única */
                verifyAccount(accounts[0].codigo, token);
              }
            });
        } else {
          secondDispatch({
            type: "LOGIN_ERROR",
            value: resJson.error_description,
          });
        }
      });
  }

  /** Submit que se ejecuta al seleccionar una cuenta de la tabla */
  function handleSubmit(accountCode, event) {
    if (event) event.preventDefault();
    secondDispatch({
      type: "IS_LOGGING_IN",
      payload: "Iniciando sesión...",
    });
    handleLoading(false);
    var loginValues = new URLSearchParams();
    loginValues.append("userName", data.username);
    loginValues.append("password", data.password);
    loginValues.append("grant_type", "password");
    fetch(config.api.url + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-application": 2,
      },
      body: loginValues.toString(),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((resJson) => {
        verifyAccount(accountCode, resJson.access_token);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /** Funcion que verifica si se redirige a la pantalla principal o se crea primero el grupo de terminal de la cuenta */
  function verifyAccount(accountCode, token) {
    fetch(config.api.url + "grupos-terminales/" + accountCode, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resJson) => {
        if (resJson.error === null) {
          /** Si el grupo de terminales no existe entonces se crea uno nuevo */
          if (resJson.exitoso === false) {
            fetch(config.api.url + "grupos-terminales", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
              },
              body: JSON.stringify({
                nombre: accountCode,
                description: "Grupo de la cuenta: " + accountCode,
                grupoPadreId: 1,
              }),
            })
              .then((res) => {
                return res.json();
              })
              .then((resJson) => {
                if (resJson.exitoso) {
                  /** Si se creo correctamente se redirige a la pantalla principal */
                  dispatch({
                    type: "LOGIN",
                    payload: { user: state.username, token: token },
                  });
                  window.location.replace(
                    "/consulta-terminales/" + accountCode
                  );
                } else {
                  message.error(resJson.error.toString());
                }
              });
          } else {
            /** En caso de que ya exista un grupo solo se redirige a la pantalla principal */
            /** Se guardan las credenciales en el contexto de autorización */
            dispatch({
              type: "LOGIN",
              payload: { user: state.username, token: token },
            });
            window.location.replace("/consulta-terminales/" + accountCode);
          }
        } else {
          message.error(resJson.error.toString);
        }
      });
  }

  /** Funcion que se encarga de la busqueda en la tabla de cuentas */
  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentAccounts = state.accounts;
    const formattedValue = currentValue.toLowerCase();
    const filteredAccounts = currentAccounts.filter((account) => {
      return (
        account.codigo.toLowerCase().includes(formattedValue) ||
        account.nombre.toLowerCase().includes(formattedValue)
      );
    });
    setDataSource(filteredAccounts);
  }
  

  /** codigo HTML */
  return (
    <div className="container">
      <div className="auth-wrapper">
        <div className="auth-inner">
          <div id="firstStep" style={{ display: state.firstStep }}>
            <Form onSubmit={showAccounts} style={{ paddingTop: 40 }}>
              <Row style={{ paddingBottom: 70 }}></Row>
              <img
                src={sistemaTressLogo}
                alt={sistemaTressLogo}
                className="center"
                style={{ width: "80%", paddingBottom: 50 }}
              ></img>
              <Form.Item>
                <Input
                  style={{ textAlign: "left" }}
                  required
                  autoFocus
                  name="username"
                  prefix={<UserOutlined />}
                  placeholder="Usuario"
                  onChange={handleInputChange}
                  value={data.username}
                />
              </Form.Item>
              <Form.Item>
                <Input.Password
                  required
                  name="password"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Clave"
                  onChange={handleInputChange}
                  value={data.password}
                />
              </Form.Item>
              <Form.Item name="remember">
                <Checkbox>Recordar Usuario</Checkbox>
              </Form.Item>
              {state.hasError ? (
                <div className="App" style={{ paddingBottom: 10 }}>
                  <Text type="danger"> {state.loginResult} </Text>
                </div>
              ) : (
                <div></div>
              )}
              <Form.Item>
                <Button
                  block
                  loading={state.isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  Entrar
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div id="secondStep" style={{ display: state.secondStep }}>
            {state.isFetching ? (
              <div className="App">
                <GtiLoading
                  loadingTitle={
                    state.loadingTitle !== null
                      ? state.loadingTitle
                      : "Cargando información de cuentas"
                  }
                  paddingTop={250}
                />
              </div>
            ) : (
              <div className="App">
                <p style={{ textAlign: "left" }}>
                  <Text strong style={{ fontSize: 16 }}>
                    Cuenta
                  </Text>
                </p>
                <Divider style={{ "background-color": "white", height: 2 }} />
                <Row>
                  <Col span={2}>
                    <div>
                      <UserOutlined />
                    </div>
                  </Col>
                  <Col span={16}>{data.username}</Col>
                  <Col span={6}>
                    <Button type="link" onClick={() => window.location.reload()}>
                      <PoweroffOutlined />
                      {" Cerrar sesión"}
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ "background-color": "white", height: 2 }} />
                <p style={{ textAlign: "left", paddingBottom: 20 }}>
                  Selecciona la cuenta a la que deseas entrar.
                </p>
                <p style={{ paddingBottom: 20, textAlign: "right"  }}>
                <GtiSearch
                    placeholder={"Buscar..."}
                    value={value}
                    width={300}
                    onSearchFunction={(e) => generalSearch(e)}
                  />
                </p>
                <Row>
                  <Col span={24}>
                    <GtiTable
                      emptyText={"No hay cuentas que mostrar"}
                      paginationElement={"cuentas"}
                      pageSize={3}
                      position={"topRight"}
                      dataSource={dataSource !== null ? dataSource : []}
                      columns={columns}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
