import React from "react";
import DevicesManager from "../DevicesManager";
import { Row, Col } from "antd";
import { AuthContext } from "../../App";
import config from "../../config";
//Llamada a los componentes de Ant Design dentro de Layout

const initialState = {
  groupId: 0,
  isDifferentThanZero: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_DEVICE_GROUP_SUCCESS":
      return {
        ...state,
        groupId: action.payload,
        isDifferentThanZero: true,
      };
    default:
      return state;
  }
};

/** Exportacion de la pagina principal */
export const Home = (props) => {
  /** Pagina de inicio */
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { state: authState } = React.useContext(AuthContext);

  React.useEffect(() => {
          fetch(config.api.url + "grupos-terminales/" + props.match.params.id, {
            headers: {
              Authorization: `bearer ${authState.token}`,
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((resJson) => {
              if (resJson.exitoso) {
                dispatch({
                  type: "OBTAIN_DEVICE_GROUP_SUCCESS",
                  payload: resJson.data.id,
                });
              }
            });
  }, [`bearer ${authState.token}`]);

  return (
    <div>
      {state.isDifferentThanZero ? (
        <Row>
          <Col span={24}>
            <DevicesManager groupId={state.groupId}></DevicesManager>
          </Col>
        </Row>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Home;
